// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

export const issue = new schema.Entity("issues");
export const lineItem = new schema.Entity("lineItems", { issues: [issue] });
export const order = new schema.Entity(
  "orders",
  {
    lineItems: [lineItem]
  },
  { idAttribute: "number", processStrategy: entity => camelizeKeys(entity) }
);
export const orders = new schema.Array(order);
