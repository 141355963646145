// @flow
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { fetchWeightRequest, fetchWeight } from "../actions";

////////////////////////////// WEIGHT REDUCER
// Most up to date reading from scale
const weightInitial = 0;
const weight = handleActions(
  {
    [fetchWeight]: { next: (state, action) => action.payload.weight }
  },
  weightInitial
);

////////////////////////////// LOADING REDUCER
type Loading = {
  gettingWeight: boolean
};
const loadingInitialState: Loading = {
  gettingWeight: false
};
const loading = handleActions(
  {
    [fetchWeightRequest]: (state, action) => ({
      ...state,
      gettingWeight: true
    }),
    [fetchWeight]: (state, action) => ({ ...state, gettingWeight: false })
  },
  loadingInitialState
);

///////////////////////////// COMBINE REDUCER
const weightReducer = combineReducers({
  weight,
  loading
});

export default weightReducer;
