// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./ErrorPage.css";

type Props = {
  location: any // Comes from the withRouter connector
};

class ErrorPage extends Component {
  props: Props;

  render() {
    return (
      <div className="error-page-container">
        <div className="error-page-main">
          <h3 className="error-page-title">Oops! Parece que algo deu errado</h3>
          {JSON.stringify(this.props.location.state)}
        </div>
      </div>
    );
  }
}

export default withRouter(ErrorPage);
