const colorBarcodes = {
  black: 'CONTROL,BLACK',
  blue: 'CONTROL,BLUE',
  cyan: 'CONTROL,CYAN',
  green: 'CONTROL,GREEN',
  pink: 'CONTROL,PINK',
  purple: 'CONTROL,PURPLE',
  red: 'CONTROL,RED',
  yellow: 'CONTROL,YELLOW',
};

export default colorBarcodes;
