// @flow
import React, { Component } from "react";
import _ from "lodash";
// Components
import { WeightGetter } from "../../weight";
import { PortioningSizeItem } from "../../common/";
// Types
import type { Issue, LineItem, Mix } from "../../types";
//Helpers
import blendColorsHelper from "../../common/blendColorsHelper";
// Styles
import "./ActiveLineItem.css";

type Props = {
  imageUrl: string,
  name: string,
  sku: string,
  optionValues: string | [],
  quantityOrdered: number,
  mixPreWeightedQuantity: number,
  prePrepQuantity: number,
  putOnHoldAt: ?string,
  quantityUnit: string,
  currentWeight: number,
  canEditError: boolean,
  portioningSize: number,
  issues: Issue[],
  weightLimited: boolean,
  lineItems: LineItem[],
  mix: Mix,
  mixIndex: number,
  observation: string,
  handleAddError: () => void,
};

class ActiveLineItem extends Component {
  props: Props;

  render() {
    let lineItemColor = "";
    if (
      this.props.issues.length > 0 &&
      _.find(this.props.issues, (o) => {
        return !o.discardedAt;
      })
    ) {
      lineItemColor = "line-item-red";
    }

    return (
      <div className="blend-active-line-item-main">
        <WeightGetter />
        <div className={"blend-active-line-item-flexcontainer " + lineItemColor}>
          <div className="blend-active-line-item-product-detail-wrapper">
            <img
              className="blend-active-line-item-image"
              src={this.props.imageUrl}
              alt=""
            />

            <div className="blend-active-line-product-details">
              <div>
                <div className="blend-active-line-item-title-container">
                  <p className="blend-active-line-item-title">
                    {this.props.name} ({this.props.sku}){" "}
                    {this.props.mix ? (
                      <span
                        className="blend-active-line-item-title-mix-name"
                        style={{
                          color: blendColorsHelper.colors[this.props.mixIndex],
                        }}
                      >{` - ${this.props.mix.name}`}</span>
                    ) : null}
                    <PortioningSizeItem
                      portioningSize={this.props.portioningSize}
                      quantityUnit={this.props.quantityUnit}
                      quantityOrdered={this.props.quantityOrdered}
                    />
                  </p>
                </div>
                {this.props.observation ? (
                  <div className="blend-active-line-item-observation-div">
                    <p className="blend-active-line-item-observation-header-text">
                      Observação:{" "}
                    </p>{" "}
                    <p className={"blend-active-line-item-observation "}>
                      {this.props.observation}
                    </p>
                  </div>
                ) : null}
                <p>{this.props.optionValues}</p>
              </div>
              <div className="blend-active-line-item-container">
                <div className="blend-active-line-item-data-container">
                  <p className="blend-active-line-item-large-font">
                    {this.props.quantityOrdered || 0} {this.props.quantityUnit}
                  </p>
                  <p>Pedido</p>
                </div>

                <div className="blend-active-line-item-data-container">
                  {this.props.mixPreWeightedQuantity === 0 ? (
                    <p className="blend-active-line-item-large-font">--</p>
                  ) : (
                    <p className="blend-active-line-item-large-font">
                      {this.props.mixPreWeightedQuantity || 0}{" "}
                      {this.props.quantityUnit}
                    </p>
                  )}
                  <p>Pré-pesado</p>
                </div>

                <div className="blend-active-line-item-data-container">
                  {this.props.prePrepQuantity === 0 ? (
                    <p className="blend-active-line-item-large-font">--</p>
                  ) : (
                    <p className="blend-active-line-item-large-font">
                      {this.props.prePrepQuantity || 0} {this.props.quantityUnit}
                    </p>
                  )}
                  <p>Total</p>
                </div>

                <div className="blend-active-line-item-data-container blend-active-line-item-box">
                  <p className="blend-active-line-item-large-font">
                    {this.props.currentWeight
                      ? this.props.currentWeight + " g"
                      : "--"}
                  </p>
                  <p>Balança</p>
                </div>
              </div>
            </div>
          </div>

          {!this.props.canEditError ? (
            <div />
          ) : (
            <div className="blend-active-line-item-data-container">
              <i
                className="fa fa-exclamation-circle blend-active-error-icon fa-2x"
                aria-hidden="true"
                onClick={this.props.handleAddError}
              />
            </div>
          )}

          <div className="blend-active-line-item-biper-colors">
            <div className="blend-active-line-item-biper-color">
              <div className="blend-active-line-item-purple-block" /> <p>Voltar</p>{" "}
            </div>
            <div className="blend-active-line-item-biper-color">
              <div className="blend-active-line-item-red-block" /> <p>Apagar</p>{" "}
            </div>
            <div className="blend-active-line-item-biper-color">
              <div className="blend-active-line-item-yellow" />{" "}
              <p> Pesar Mais </p>{" "}
            </div>
            <div className="blend-active-line-item-biper-color">
              <div className="blend-active-line-item-cyan" /> <p> Descer ↓ </p>
            </div>
            <div className="blend-active-line-item-biper-color">
              <div className="blend-active-line-item-green" /> <p> Concluir </p>
            </div>
          </div>
        </div>

        {this.props.issues.length > 0 ? (
          <div className={`blend-active-line-item-issue-container ${lineItemColor}`}>
            {this.props.issues.map((issue, ind) => (
              <p key={ind}>
                <b>{issue.type}::</b> {issue.details}
                {issue.discardedAt && issue.solution
                  ? ` :: Solução: ${issue.solution}`
                  : null}
              </p>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default ActiveLineItem;
