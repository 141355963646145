// @flow
import { createSelector } from "reselect";
// Types
import type { StoreState } from "../types/StoreState";

// Main state selector
export const thisStateSelector = (state: any): StoreState =>
  state.configuration;

export const configurationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.configuration
);

export const distributionCenterSelector = createSelector(
  configurationSelector,
  configuration => configuration.distributionCenter
);

// General Loading Related
export const gettingLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.getting
);

export const settingLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.setting
);
