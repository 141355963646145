// @flow
import { createSelector } from "reselect";
import _ from "lodash";

const thisStateSelector = (state: any): StoreState => {
  return state.blendPrePreparations;
};

//////////// MAPS ////////////
const productionMixesMapSelector = createSelector(
  thisStateSelector,
  (state) => state.productionMixes
);

const lineItemsMapSelector = createSelector(
  thisStateSelector,
  (state) => state.mixLineItems
);

const mixLineItemsMapSelector = createSelector(
  thisStateSelector,
  (state) => state.mixLineItems
);

// Map of orders
const orderMapSelector = createSelector(
  thisStateSelector,
  state => state.orders
);

const loadingMapSelector = createSelector(
  thisStateSelector,
  (state) => state.loadings
);

// Map of issues
const issuesMapSelector = createSelector(
  thisStateSelector,
  state => state.mixIssues
);

/////////////// PRODUCTION MIXES INDEX RELATED ////////////////////
// Gets the list of all mixes for preparation
export const productionMixesSelector = createSelector(
  thisStateSelector,
  productionMixesMapSelector,
  (state, mixesMap) =>
    state.currentProductionMixesList.map((id) => mixesMap[id])
);

//Get the mix based on the mixId
export const currentMixSelector = createSelector(
  thisStateSelector,
  state => state.currentMix
);

//Get the mix based on the mixId
export const currentMixOrderSelector = createSelector(
  thisStateSelector,
  orderMapSelector,
  (state, orderMap) => orderMap[state.currentMixOrderNumber]
);

// Returns a boolean for whether we have an active valid order
export const hasMixOrderSelector = createSelector(
  thisStateSelector,
  currentMixOrderSelector,
  (state, mixOrder) => mixOrder != null
);

// Returns the observations of the current order
export const getMixOrderObservationSelector = createSelector(
  thisStateSelector,
  currentMixOrderSelector,
  (state, order) => (order ? order.observation : null)
);

/////////////// PRODUCTS RELATED ////////////////////
// Get all lineItems for the mix
export const currentMixLineItemsSelector = (
  state: StoreState
) => {
  const mix = currentMixSelector(state);
  const lineItemMap = lineItemsMapSelector(state);

  // If the order is null or undefined
  if (!mix || !mix.lineItems) return [];

  const lineItems = mix.lineItems.map(l => lineItemMap[l]);

  return _.orderBy(lineItems, ["isPrePrepComplete", (l) => l.putOnHoldAt ? l.putOnHoldAt : '']);
};

export const mixLineItemsToPrintSelector = createSelector(
  thisStateSelector,
  (state) => state.mixLineItemsToPrint
);

// Are there line items that have not been pre weighted?
export const currentMixHasItemsToPreWeigh = (state: StoreState) => {
  const numberItems = getNumberCurrentMixLineItemsSelector(state);
  const numberPreWeightedItems = getNumberCurrentMixPreWeightedLineItemsSelector(state);

  return numberItems > numberPreWeightedItems;
};

// Are there line items that have not been prepared?
export const currentMixHasItemsToPrepare = (state: StoreState) => {
  const numberItems = getNumberCurrentMixLineItemsSelector(state);
  const numberCompletedItems = getNumberCurrentMixCompletedLineItemsSelector(state);

  return numberItems > numberCompletedItems;
};

// Are there line items that have not been prepared?
export const currentMixHasItemsWithEmptyPrePrepQuantity = (state: StoreState) => {
  const numberItems = getNumberCurrentMixLineItemsSelector(state);
  const numberPrePreparedItems = getNumberCurrentMixPrePreparedLineItemsSelector(state);

  return numberItems > numberPrePreparedItems;
};

// Returns the number of line items in the given order
export const getNumberCurrentMixLineItemsSelector = (
  state: StoreState
) => {
  const lineItems = currentMixLineItemsSelector(state);
  return lineItems.length;
};

// Returns the number of pre weighted line items in the mix
export const getNumberCurrentMixPreWeightedLineItemsSelector = (
  state: StoreState
) => {
  const lineItems = currentMixLineItemsSelector(state);

  return _.filter(lineItems, (l) => (l.mixPreWeightedQuantity && l.mixPreWeightedQuantity > 0)).length;
};

// Returns the number of pre weighted line items in the mix
export const getNumberCurrentMixPrePreparedLineItemsSelector = (
  state: StoreState
) => {
  const lineItems = currentMixLineItemsSelector(state);

  return _.filter(lineItems, (l) => (l.prePrepQuantity && l.prePrepQuantity > 0)).length;
};

// Returns the number of line items in the mix that have been processed
export const getNumberCurrentMixCompletedLineItemsSelector = (
  state: StoreState
) => {
  const lineItems = currentMixLineItemsSelector(state);

  return _.filter(lineItems, { isPrePrepComplete: true }).length;
};

// Returns the issues belonging to the line items of the current mix
export const currentMixIssuesSelector = createSelector(
  thisStateSelector,
  currentMixLineItemsSelector,
  issuesMapSelector,
  (state, items, issueMap) => {
    const issueKeys = items.reduce(
      (prev, item) => [...prev, ...item.issues],
      []
    );
    return issueKeys.map(key => issueMap[key]);
  }
);

// Returns a boolean whether the current order has issues
export const currentMixHasIssuesSelector = createSelector(
  thisStateSelector,
  currentMixIssuesSelector,
  (state, issues) => {
    return (
      issues.length > 0 &&
      _.find(issues, o => {
        return !o.discardedAt;
      })
    );
  }
);

////////////////// LOADING RELATED ////////////////////
export const gettingMixesLoadingSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.gettingProductionMixes
);
