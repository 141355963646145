// @flow
import React, { Component } from 'react';
import moment from 'moment';
import StatePresentationName from './StatePresentationName';
// Types
import type { OrderAasm } from '../types';
import { NormalizedOrder } from '../types';
// Styling
import './OrderItem.css';

type Props = {
  order: NormalizedOrder,
  color: String,
  mapInd: int,
};

class OrderItem extends Component {
  props: Props;

  render() {
    const order = this.props.order;
    const color = this.props.color;

    return (
      <tr>
        <td style={{ color: color }}>
          {order.routeId ? `Rota #${order.routeId}` : 'Sem rota'}
        </td>
        <td>
          {order.number}

          {order.shouldPrintSaleReceipt ? (
            <i
              className="fa fa-print"
              title="A nota fiscal do cliente deve ser impressa."
              style={{ color: 'green', marginLeft: '5px' }}
            />
          ) : null}
          {order.weightLimited ? (
            <i
              className="fa fa-balance-scale"
              title="O peso não deve exceder o total pedido."
              style={{ color: 'green', marginLeft: '5px' }}
            />
          ) : null}
        </td>
        <td>{moment(order.deadline).fromNow()}</td>
        <td>{moment(order.updatedAt).fromNow()}</td>
        <td>
          {order.internalBoxesCount ? order.internalBoxesCount : '0'} Caixa(s)
        </td>
        <td>
          <StatePresentationName state={order.state} />
        </td>
      </tr>
    );
  }
}

export default OrderItem;
