// @flow
import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";
import _ from "lodash";
// Types
import type { Product } from "../types/product";
// Actions
import * as actions from "../actions";

////////////////////////////// CURRENT PRE-PREP PRODUCTS INDEX
const currentProductListInitialState = [];
const currentProductList = handleActions(
  {
    [combineActions(actions.fetchPrePreparationProductIndex)]: {
      next: (state, action) => action.payload.result,
    },
  },
  currentProductListInitialState
);

////////////////////////////// MAP OF PRE PREPARATION INDEX
const productsInitialState = {};
const products = handleActions(
  {
    [combineActions(
      actions.fetchPrePreparationProductIndex,
      actions.fetchPrePreparationOrdersByProduct
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.products,
      }),
    },
  },
  productsInitialState
);

////////////////////////////// MAP OF PRE PREPARATION ORDERS KEYED ON PRODUCT
const ordersInitialState = {};
const orders = handleActions(
  {
    [combineActions(actions.fetchPrePreparationOrdersByProduct)]: {
      next: (state, action) => ({
        ...action.payload.entities.orders,
      }),
    },
  },
  ordersInitialState
);

////////////////////////////// LOADING REDUCER
type Loading = {
  gettingPrePreparationIndex: boolean,
};

const loadingInitialState = {
  gettingPrePreparationIndex: false,
};

const loading = handleActions(
  {
    [combineActions(actions.fetchPrePreparationProductIndexRequest)]: (
      state,
      action
    ) => ({
      ...state,
      gettingPrePreparationIndex: true,
    }),
    [combineActions(actions.fetchPrePreparationProductIndex)]: (
      state,
      action
    ) => ({
      ...state,
      gettingPrePreparationIndex: false,
    }),
  },
  loadingInitialState
);

const prePreparations: State = combineReducers({
  currentProductList,
  products,
  orders,

  loading,
});

export default prePreparations;
