const weightServerAddress = "http://localhost:8000";

const Weight = {
  async getWeight() {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const result = await fetch(`${weightServerAddress}/weights/current`, {
      method: "GET",
      headers: headers
    });

    if (!result.ok) {
      throw new Error(result.statusText);
    }

    const response = await result.json();

    return response;
  }
};

export default Weight;
