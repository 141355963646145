// @flow
import React, { Component } from "react";
// Style
import "./CorrectionItem.css";
// Types
import type { LineItem } from "../types";

type Props = {
  lineItems: LineItem[],
  quantityUnit: string,
  unitPrice: number,
  quantityOrdered: number,
  weightLimited: boolean
};

class CorrectionItem extends Component {
  props: Props;

  calcDifPrice() {
    // We should never change the quantity for products sold in units
    if (this.props.quantityUnit === "un") return 0;

    const variationTolerance = 0.1; // Maximum amount a single item can be changed by
    const correctionFactor = 1.01; // Maximum amount an order can be changed by when not weight limited

    const lineItems = this.props.lineItems;

    // If for some reason the price of one of the lineItems is not imported we should not attempt to calculate the correction factor
    const containsNoNull = lineItems.reduce(
      (accumulator, l) => accumulator && !(l.unitPrice == null),
      true
    );
    if (!containsNoNull) {
      return 0;
    }

    // Compute the ordered and actual price of the current order
    let priceOrdered = lineItems.reduce(
      (accumulator, l) => accumulator + l.quantityOrdered * l.unitPrice,
      0
    );
    const priceActual = lineItems.reduce(
      (accumulator, l) =>
        accumulator +
        (l.isProcessed ? l.quantityActual : l.quantityOrdered) * l.unitPrice,
      0
    );

    // For orders which we don't need to be strict about price provide the preparer some wiggle room
    if (!this.props.weightLimited) {
      priceOrdered *= correctionFactor;
    }

    // Amount we need to vary the current item's weight to reach our ideal weight
    const variationWeight = (priceOrdered - priceActual) / this.props.unitPrice;
    // Maximum amount that we can vary the current item's weight
    const maxVariationWeight = this.props.quantityOrdered * variationTolerance;

    return parseInt(
      Math.abs(variationWeight) > Math.abs(maxVariationWeight)
        ? maxVariationWeight * Math.sign(variationWeight)
        : variationWeight
    );
  }

  render() {
    return (
      <div className="active-line-item-data-container">
        <p
          className={
            this.calcDifPrice() < 0
              ? `active-line-item-large-font negative`
              : this.calcDifPrice() === 0
              ? `active-line-item-large-font`
              : `active-line-item-large-font positive`
          }
        >
          {this.calcDifPrice()} {this.props.quantityUnit}
        </p>
        <p>Correção</p>
      </div>
    );
  }
}

export default CorrectionItem;
