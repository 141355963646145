// @flow
import React from "react";
import qs from "qs";
// Components
import Login from "../components/Login";
import { Redirect } from "react-router-dom";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Import Actions
import { login } from "../actions";
// Selectors
import {
  loggedInSelector,
  employeeLoggingInLoadingSelector,
  loginErrorsSelector
} from "../selectors";
// Connectors
// TODO: This should be imported through index.js as a named import
import distributionCenterConnector from "../../configurationModule/connectors/distributionCenterConnector";
// Types
import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "../../common";

type RouterProps = ContextRouter;
type Props = {
  loggedIn: boolean,
  isLoggingIn: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean
  },
  submitLogin: () => void,

  // From distributionCenterConnector
  distributionCenter: string,
  distributionCenterLoading: boolean
} & RouterProps;

class LoginPage extends React.Component {
  props: Props;
  render() {
    // If the distributionCenter is loading we should not do anything else we risk going to a place that can make API requests without a correct DC
    if (this.props.distributionCenterLoading) return null;

    if (this.props.loggedIn) {
      const params = qs.parse(
        this.props.location.search ? this.props.location.search.substr(1) : ""
      );

      // If the app is NOT configured we should redirect to the configuration page
      if (!this.props.distributionCenter) {
        return (
          <Redirect to={params.to ? `/configuration?to=${params.to}` : "/"} />
        );
      }

      // Else continue to the regular page
      return <Redirect to={params.to ? `/${params.to}` : "/"} />;
    }
    return (
      <Login
        submitLogin={this.props.submitLogin}
        isLoggingIn={this.props.isLoggingIn}
        loginErrors={this.props.loginErrors}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state),
    isLoggingIn: employeeLoggingInLoadingSelector(state),
    loginErrors: loginErrorsSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    submitLogin: (username, password) => {
      dispatch(login(username, password));
    }
  };
};

export default distributionCenterConnector(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LoginPage)
  )
);
