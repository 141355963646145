// @flow
import React, { Component } from "react";

import "./Button.css";

type Props = {
  text: string,
  icon?: string,
  buttonType: string,
  disabled?: boolean,
  type?: string,
  loading: boolean,
  onClick?: () => void
};

class Button extends Component {
  props: Props;
  render() {
    let text = this.props.text;
    if (this.props.loading) {
      text = "Carregando...";
    }

    return (
      <button
        className={`button-main ${this.props.buttonType}`}
        onClick={this.props.onClick}
        disabled={this.props.loading || this.props.disabled}
        type={this.props.type}
      >
        {this.props.icon != null
          ? <div className="row button-row">
              <i className={`button-icon ${this.props.icon}`} />
              {" "}
              &nbsp;
              {" "}
              {this.props.text}
            </div>
          : <div className="row button-row">
              {this.props.loading
                ? <i
                    className="fa fa-spinner fa-pulse fa-1x"
                    aria-hidden="true"
                  />
                : <div />}
              <span className="button-just-text">{text}</span>
            </div>}
      </button>
    );
  }
}

export default Button;
