// @flow
import { createAction } from "redux-actions";
import { logAPIException } from "../../logHelper";
// Types
import type { Dispatch } from "../../common";
// Apis
import Api from "../api";

// Search fulfillments
export const fetchIssueSourcesRequest = createAction(
  "FETCH_ISSUE_SOURCES_REQUEST",
);
export const fetchIssueSources = createAction("FETCH_ISSUE_SOURCES");

export function findIssueSources() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchIssueSourcesRequest());
    try {
      const response = await Api.getIndex();
      dispatch(fetchIssueSources(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchIssueSources(err));
    }
  };
}
