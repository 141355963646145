// @flow
/**
 * PRE PREPARATION MODULE EXPORTS
 * This is the "interface" file for this module.
 * You should only use exports from this file.
 */
/**
 * Imports
 * =======
 * Import exports from files inside
 * the module
 */
import PrePreparationIndexPage from './pages/PrePreparationIndexPage'
import PrePreparationProductPage from './pages/PrePreparationProductPage'
import reducer from "./reducers";

/**
* Reducer
* =======
* The default export is the reducer to be integrated in the Redux Store.
* Its state is defined too, and exported as the type StoreState.
*/
export default reducer;

/**
 * Selectors
 * =========
 */

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */

/**
* Pages
* =======
* Pages are connected components that work stadalone from within router.
*/
export {
    PrePreparationIndexPage,
    PrePreparationProductPage
}

/**
* HOCs and Connectors
* ===================
* Higher order components and connectors wrap the children component
* and add behavior or props that depend on the internal user state.
*/
/**
 * Types
 * =====
 * Flow types to be used in other modules, generally with connectors.
 */