// Takes in unitForQuantity and returns displayable units
export const unitConverter = (unitForQuantity: string) => {
  return unitForQuantity === 'g' ? 'Kg' : 'un';
};

// Converts quantity into locale specific value taking into account quantity
export const quantityConverter = (
  quantity: number,
  unitForQuantity: string,
) => {
  if (quantity == null) {
    return '- -';
  }
  if (quantity < 1000) {
    return `${quantity}${unitForQuantity}`;
  }
  return (
    parseFloat(
      unitForQuantity === 'g' ? quantity / 1000 : quantity,
    ).toLocaleString('pt-br') + unitConverter(unitForQuantity)
  );
};

// Get image file size from base64 string
export const getImageFileSize = (base64str: string) => {
  const lastTwoCharacters = base64str.slice(-2);

  const numberOfEqualCharactersInTheString = lastTwoCharacters === '==' ? 2 : 1;

  const fileSize =
    (base64str.length * 3) / 4 - numberOfEqualCharactersInTheString;
  return fileSize;
};
