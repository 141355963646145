// @flow
import { createAction } from "redux-actions";
// Logger
import { logAPIException } from "../../logHelper";
// Apis
import Api from "../api";

////////////////// BLEND PRE-PREPERATION RELATED ///////////////////////////
// Get Mixes index
export const fetchProductionMixesRequest = createAction(
  "FETCH_PRODUCTION_MIXES_REQUEST"
);
export const fetchProductionMixes = createAction(
  "FETCH_PRODUCTION_MIXES"
);

export function getProductionMixes(distributionCenter: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProductionMixesRequest());
    try {
      const response = await Api.getProductionMixes(distributionCenter);
      dispatch(fetchProductionMixes(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchProductionMixes(err));
    }
  };
}

export const postStartBlendPrePreparationRequest = createAction(
  "POST_START_BLEND_PRE_PREPARATION_REQUEST"
);
export const postStartBlendPrePreparation = createAction("POST_START_BLEND_PRE_PREPARATION");

export function startBlendPrePreparation(distributionCenter: string) {
  return async (dispatch: Dispatch) => {
    dispatch(postStartBlendPrePreparationRequest());
    try {
      const response = await Api.startBlendPrePreparation(distributionCenter);
      dispatch(postStartBlendPrePreparation(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postStartBlendPrePreparation(err));
    }
  };
}

export const fetchProductionMixRequest = createAction(
  "FETCH_PRODUCTION_MIX_REQUEST"
);
export const fetchProductionMix = createAction("FETCH_PRODUCTION_MIX");

export function getProductionMix(mixId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchProductionMixRequest());
    try {
      const response = await Api.getProductionMix(mixId);
      dispatch(fetchProductionMix(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchProductionMix(err));
    }
  };
}

export const putProductionMixRequest = createAction(
  "PUT_PRODUCTION_MIX_REQUEST"
);
export const putProductionMix = createAction(
  "PUT_PRODUCTION_MIX"
);

export function updateProductionMix(mixId: string, params) {
  return async (dispatch: Dispatch) => {
    dispatch(putProductionMixRequest());
    try {
      const response = await Api.updateProductionMix(mixId, params);
      dispatch(putProductionMix(response));
    } catch (err) {
      logAPIException(err);
      dispatch(putProductionMix(err));
    }
  };
}

export const putProductionMixPrePreparedQuantityRequest = createAction(
  "PUT_PRODUCTION_MIX_PRE_PREPARED_QUANTITY_REQUEST"
);
export const putProductionMixPrePreparedQuantity = createAction(
  "PUT_PRODUCTION_MIX_PRE_PREPARED_QUANTITY"
);
export const getProductionMixToPrintProducts = createAction(
  "GET_PRODUCTION_MIX_TO_PRINT_PRODUCTS"
);

export function addProductionMixPrePreparedQuantity(mixId: string, quantity: number) {
  return async (dispatch: Dispatch) => {
    dispatch(putProductionMixPrePreparedQuantityRequest());
    try {
      const response = await Api.addBlendPrePreparedQuantity(mixId, quantity);
      dispatch(putProductionMixPrePreparedQuantity(response));
      dispatch(getProductionMixToPrintProducts(response));
    } catch (err) {
      logAPIException(err);
      dispatch(putProductionMixPrePreparedQuantity(err));
    }
  };
}