// @flow
import { handleActions, combineActions } from "redux-actions";
import { combineReducers } from "redux";
// Actions
import * as Actions from "../actions";
// Types
import type { Reducer } from "redux";
import type { Action } from "../../common";

// Application configurations
const initialConfigurations = {
  distributionCenter: null
};
const configuration: Reducer<{}, Action> = handleActions(
  {
    [combineActions(
      Actions.setDistributionCenter,
      Actions.getDistributionCenter
    )]: {
      next: (state, action) => ({
        ...state,
        distributionCenter: action.payload
      })
    }
  },
  initialConfigurations
);

// Loading
const initialLoadingState = {
  getting: false,
  setting: false
};
const loading = handleActions(
  {
    [Actions.getDistributionCenterRequest]: (state, action) => ({
      ...state,
      getting: true
    }),
    [Actions.getDistributionCenter]: (state, action) => ({
      ...state,
      getting: false
    }),
    [Actions.setDistributionCenterRequest]: (state, action) => ({
      ...state,
      setting: true
    }),
    [Actions.setDistributionCenter]: (state, action) => ({
      ...state,
      setting: false
    })
  },
  initialLoadingState
);

const reducers = combineReducers({
  configuration,

  loading
});

export default reducers;
