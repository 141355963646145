// @flow
import React, { Component } from "react";
// Composers

// Components
import Modal from "react-modal";
import BarcodeInput from "./BarcodeInput";
import colorBarcodes from "../constants/colorBarcodes";
// Selectors

// Styles
import "./CompletionModal.css";

const CompleteWithErrorBody = ({ handleSubmit }) => (
  <div className="preparation-modal-main">
    <div className="preparation-item-biper-colors">
      <div className="preparation-item-biper-color">
        <div className="active-line-item-red-block" /> <p>Retornar</p>{" "}
        <div className="active-line-item-green" /> <p>Aprovar</p>{" "}
      </div>
    </div>
    <h1 className="preparation-modal-title">
      Pedido contêm erros. Fechar com erros?
    </h1>

    <BarcodeInput handleSubmit={handleSubmit} />
  </div>
);

const CompleteInputBoxesBody = ({ handleSubmit }) => (
  <div className="preparation-modal-main">
    <div className="preparation-item-biper-colors">
      <div className="preparation-item-biper-color">
        <div className="active-line-item-red-block" /> <p>Retornar</p>{" "}
      </div>
    </div>
    <h1 className="preparation-modal-title">Bipe o número de caixas</h1>

    <BarcodeInput handleSubmit={handleSubmit} />
  </div>
);

const CompleteConfirmationBody = ({ handleSubmit }) => (
  <div className="preparation-modal-main">
    <div className="preparation-item-biper-colors">
      <div className="preparation-item-biper-color">
        <div className="active-line-item-red-block" /> <p>Retornar</p>{" "}
        <div className="active-line-item-green" /> <p>Aprovar</p>{" "}
      </div>
    </div>
    <h1 className="preparation-modal-title">
      Bipe o código de barras para fechar o pedido
    </h1>

    <BarcodeInput handleSubmit={handleSubmit} />
  </div>
);

type Props = {
  isOpened: boolean,
  orderNumber: string,
  username: string,
  shouldPrint?: boolean,
  closeModal: () => void,
  completeOrder?: () => void,
  handlePrintOrderLabel: ({ number: string }) => void
};

type State = {
  selectedError: number,
  modalStage: "boxes" | "printing" | "confirmation",
  boxesCount: number
};

class CompletionModal extends Component {
  state: State;
  handleSubmitBoxes: (barcode: string) => void;
  handleSubmitPrinting: (barcode: string) => void;
  handleSubmit: (barcode: string) => void;
  handlePrintLabel: (numberOfBoxes: number) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedError: 0,
      modalStage: "boxes",
      boxesCount: 0
    };
  }

  handleSubmitBoxes(barcode: string) {
    // Once we collect the number of boxes we should move onto the confirmation stage
    if (/^\+?\d+$/.test(barcode)) {
      this.setState({
        boxesCount: parseInt(barcode, 10),
        modalStage: "confirmation"
      });
      // Print the labels corresponding to the number of boxes selected
      if (this.props.shouldPrint) {
        this.props.handlePrintOrderLabel({
          number: this.props.orderNumber,
          numberOfBoxes: parseInt(barcode, 10)
        });
      }
    } else {
      this.handleCloseModal();
    }
  }

  // Submits the order to the server, closes the modal
  handleSubmit(barcode: string) {
    // If the barcode matches the user ID
    // Related to closing order
    if (barcode === colorBarcodes.green) {
      this.props.handleSubmitOrder(
        this.props.orderNumber,
        this.state.boxesCount || 0
      );
      if (this.props.completeOrder) this.props.completeOrder();
      this.handleCloseModal();
    } else if (barcode === colorBarcodes.red) {
      this.handleCloseModal();
    }
  }

  handleCloseModal() {
    this.setState({ modalStage: "boxes" });
    this.props.closeModal();
  }

  render() {
    if (this.props.isGettingIssueSources) {
      return null;
    }

    const determineBody = stage => {
      if (stage === "boxes") {
        return (
          <CompleteInputBoxesBody
            handleSubmit={this.handleSubmitBoxes.bind(this)}
          />
        );
      } else if (stage === "confirmation") {
        return (
          <CompleteConfirmationBody
            handleSubmit={this.handleSubmit.bind(this)}
          />
        );
      } else {
        return null;
      }
    };

    const customStyles = {
      content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
      }
    };

    return (
      <Modal
        isOpen={this.props.isOpened}
        shouldCloseOnOverlayClick={true}
        contentLabel="Completion Modal"
        onRequestClose={this.handleCloseModal.bind(this)}
        style={customStyles}
      >
        {this.props.hasIssues ? (
          <CompleteWithErrorBody handleSubmit={this.handleSubmit.bind(this)} />
        ) : (
          determineBody(this.state.modalStage)
        )}
      </Modal>
    );
  }
}

CompletionModal.defaultProps = {
  shouldPrint: true
};

export default CompletionModal;
