// @flow
import React, { Component } from "react";
import _ from "lodash";
// Components
import { WeightGetter } from "../../weight";
import Button from "../../common/components/Button";
import { PortioningSizeItem } from "../../common";
// Types
import type { Issue, Mix } from "../types";
//Helpers
import blendColorsHelper from "../../common/blendColorsHelper";
// Styles
import "./ActiveLineOrder.css";

const ActiveLineOrder = (props) => {
  const { lineItem } = props.order;
  const currentWeight = props.currentWeight;
  return (
    <div className="active-line-order-main">
      <WeightGetter />
      <hr className="active-line-order-line" />
      <div className={"active-line-order-flexcontainer "}>
        {(lineItem.observation && (
          <div className="active-line-order-observation">
            <p>{lineItem.observation}</p>
          </div>
        )) ||
          null}
        <div className="active-line-order-data-content">
          <div className="active-line-order-data-content-title">
            <p>
              {lineItem.orderNumber}
              {lineItem.starred && (
                <i className="fa fa-star" aria-hidden="true" />
              )}
              <PortioningSizeItem
                portioningSize={lineItem.portioningSize}
                quantityUnit={lineItem.quantityUnit}
                quantityOrdered={lineItem.quantityOrdered}
              />
            </p>
          </div>
          <div className="active-line-order-data-content-weight">
            <div>
              <p>
                {lineItem.quantityOrdered} {lineItem.quantityUnit}
              </p>
              <span>Pedido</span>
            </div>
            <div>
              {lineItem.prePrepQuantity === 0 ? (
                <p>--</p>
              ) : (
                <p>
                  {lineItem.prePrepQuantity} {lineItem.quantityUnit}
                </p>
              )}
              <span>Pré preparado</span>
            </div>
            <div className="active-line-item-not-highlight-color">
              {lineItem.quantityActual === 0 ? (
                <p>--</p>
              ) : (
                <p>
                  {lineItem.quantityActual} {lineItem.quantityUnit}
                </p>
              )}
              <span>Preparado</span>
            </div>
            <div className="active-line-item-actual-weight">
              {currentWeight ? currentWeight + " g" : "--"}
              <span>Balança</span>
            </div>
          </div>
          <div className="active-line-item-biper-colors">
            <div className="active-line-item-biper-color">
              <div className="active-line-item-red-block" /> <p>Apagar</p>{" "}
            </div>
            <div className="active-line-item-biper-color">
              <div className="active-line-item-yellow" />{" "}
              <p> Pesar Mais </p>{" "}
            </div>
            <div className="active-line-item-biper-color">
              <div className="active-line-item-green" /> <p> Concluir </p>
            </div>
          </div>
        </div>
        <div>
          {lineItem.issues && lineItem.issues.length > 0 ? (
            <div className={"active-line-order-issue-container"}>
              {lineItem.issues.map((issue, ind) => (
                <p key={ind}>
                  <b>{issue.type}::</b> {issue.details} ::{" "}
                  {issue.discardedAt && issue.solution
                    ? ` :: Solução: ${issue.solution}`
                    : null}
                </p>
              ))}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveLineOrder;
