// @flow
import React from "react";
// Components
import { Button, InputFieldErrorMessage } from "../../common";
// Styles
import "./ConfigurationBox.css";

type State = {
  badConfiguration: string
};

type Props = {
  isLoading: boolean,
  onSubmit: (distributionCenter: string) => void
};

class ConfigurationBox extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      badConfiguration: ""
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput(barcode: string) {
    let noValidationErrors: boolean = true;
    const validDistributionCenters = ["portao", "pituba"];

    // BARCODE FORMAT
    // The barcode should be a comma separated list where
    // Position 0: 'C'
    // Position 1: distributionCenter
    const splitBarcode = barcode.split(",");

    const distributionCenter = splitBarcode[1];
    if (!validDistributionCenters.includes(distributionCenter)) {
      this.setState({ badConfiguration: "Configuração inválida" });
      noValidationErrors = false;
    } else {
      this.setState({ badConfiguration: "" });
    }

    if (noValidationErrors) {
      this.props.onSubmit(distributionCenter);
    } else {
      console.error("Validation error on registration form.");
    }
  }

  render() {
    return (
      <div className="configuration-main">
        <div className="configuration-content">
          <div className="configuration-container">
            <div className="configuration-box-main">
              <h3>O aplicativo tem que ser configurado</h3>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.validateUserInput(this.refs.barcode.value);
                }}
              >
                <h3>Escaneie código de barras</h3>
                <InputFieldErrorMessage message={this.state.badConfiguration} />
                <input
                  ref="barcode"
                  type="password"
                  className="configuration-box-input"
                  placeholder="Código de barras"
                  autoFocus={true}
                />

                <div className="configuration-box-button">
                  <Button
                    text="ENTRAR"
                    buttonType="button-primary"
                    loading={this.props.isLoading}
                    onClick={() => {}}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigurationBox;
