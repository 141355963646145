// @flow
import React, { Component } from "react";
// Components
import Loader from "./Loader";
// Styles
import "./PeriodicGetter.css";

type Props = {
  loading: boolean,
  handleGet: () => void
};

type State = {
  countdown: number
};

const countdownStartValue = 30;

class PeriodicOrderGetter extends Component {
  state: State;
  timer: number;
  countdownTimer: number;
  decreaseCountdown: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      countdown: countdownStartValue
    };
  }

  decreaseCountdown() {
    this.setState({
      countdown:
        this.state.countdown === 0
          ? countdownStartValue
          : this.state.countdown - 1
    });
  }

  componentDidMount() {
    this.timer = setInterval(
      this.props.handleGet.bind(this),
      countdownStartValue * 1000
    );
    this.countdownTimer = setInterval(this.decreaseCountdown.bind(this), 1000);

    // We should try once as soon as we get in here
    this.props.handleGet();
  }

  componentWillReceiveProps(nextProps: Props) {
    // If we are in the loading state we should stop the timers and reset their values
    if (!this.props.loading && nextProps.loading) {
      clearInterval(this.timer);
      clearInterval(this.countdownTimer);
      this.setState({ countdown: countdownStartValue });
    } else if (this.props.loading && !nextProps.loading) {
      clearInterval(this.timer);
      clearInterval(this.countdownTimer);
      this.timer = setInterval(
        this.props.handleGet.bind(this),
        countdownStartValue * 1000
      );
      this.countdownTimer = setInterval(
        this.decreaseCountdown.bind(this),
        1000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.countdownTimer);
  }

  render() {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <div className="periodic-order-getter-main">
        <div className="periodic-order-getter-box">
          Atualizando em {this.state.countdown} segundos.
        </div>
      </div>
    );
  }
}

export default PeriodicOrderGetter;
