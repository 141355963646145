// @flow
import { createAction } from "redux-actions";
import { saveAs } from "file-saver";
// Types
import type { Dispatch } from "../common";
// Apis
import Api from "../api/orders";
// Logger
import { logAPIException } from "../logHelper";
// Selectors
import { getCurrentOrderNumberSelector } from "../selectors/orders";

// Clears the current order from the reducer
export const orderClear = createAction("ORDER_CLEAR");

// Searches for orders
export const fetchOrdersRequest = createAction("FETCH_ORDERS_REQUEST");
export const fetchOrders = createAction("FETCH_ORDERS");

export function findOrders(searchParams) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrdersRequest());
    try {
      const response = await Api.getOrders(searchParams);
      dispatch(fetchOrders(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchOrders(err));
    }
  };
}

// Gets an order by number
export const fetchOrderByNumberRequest = createAction(
  "FETCH_ORDER_BY_NUMBER_REQUEST",
);
export const fetchOrderByNumber = createAction("FETCH_ORDER_BY_NUMBER");

export function getOrderByNumber(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrderByNumberRequest());
    try {
      const response = await Api.getOrderByNumber(orderNumber);
      dispatch(fetchOrderByNumber(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchOrderByNumber(err));
    }
  };
}

// Gets order versions
export const fetchOrderVersionsRequest = createAction(
  "FETCH_ORDER_VERSIONS_REQUEST",
);
export const fetchOrderVersions = createAction("FETCH_ORDER_VERSIONS");

export function getOrderVersions(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchOrderVersionsRequest());
    try {
      const response = await Api.getOrderVersions(orderNumber);
      dispatch(fetchOrderVersions(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchOrderVersions(err));
    }
  };
}

// Updates a line item
export const updateLineItemRequest = createAction("UPDATE_LINE_ITEM_REQUEST");
export const updateLineItem = createAction("UPDATE_LINE_ITEM");

export function editLineItem(updatableLineItem) {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch(updateLineItemRequest({ itemId: updatableLineItem.id }));
    try {
      const response = await Api.updateLineItem(updatableLineItem);
      dispatch(updateLineItem(response));
      // We should request the active order again to make sure we are up to date
      // NOTE: We request the ACTIVE order. This means that the order can change.
      const orderNumber = getCurrentOrderNumberSelector(getState());
      dispatch(getOrderByNumber(orderNumber));
    } catch (err) {
      logAPIException(err);
      dispatch(updateLineItem(err));
    }
  };
}

// Used to transition the state from preparation
export const updateFinishPreparationRequest = createAction(
  "UPDATE_FINISH_PREPARATION_REQUEST",
);
export const updateFinishPreparation = createAction(
  "UPDATE_FINISH_PREPARATION",
);

export function finishPreparation(
  orderNumber: string,
  internalBoxesCount: number,
) {
  return async (dispatch: Dispatch) => {
    dispatch(updateFinishPreparationRequest());
    try {
      const response = await Api.finishPreparation(
        orderNumber,
        internalBoxesCount,
      );
      dispatch(updateFinishPreparation(response));
      // We set the current order to null since we have successfuly transtitioned this order
      dispatch(orderClear());
    } catch (err) {
      logAPIException(err);
      dispatch(updateFinishPreparation(err));
    }
  };
}

// Used to transition the state from packaging
export const updateFinishPackagingRequest = createAction(
  "UPDATE_FINISH_PACKAGING_REQUEST",
);
export const updateFinishPackaging = createAction("UPDATE_FINISH_PACKAGING");

export function finishPackaging(
  orderNumber: string,
  packagedBoxesCount: number,
) {
  return async (dispatch: Dispatch) => {
    dispatch(updateFinishPackagingRequest());
    try {
      const response = await Api.finishPackaging(
        orderNumber,
        packagedBoxesCount,
      );
      dispatch(updateFinishPackaging(response));
      // We set the current order to null since we have successfuly transtitioned this order
      dispatch(orderClear());
    } catch (err) {
      logAPIException(err);
      dispatch(updateFinishPackaging(err));
    }
  };
}

// Used to transition the state from preparation
export const postStartPreparationRequest = createAction(
  "POST_START_PREPARATION_REQUEST",
);
export const postStartPreparation = createAction("POST_START_PREPARATION");

export function startPreparation(distributionCenter: string) {
  return async (dispatch: Dispatch) => {
    dispatch(postStartPreparationRequest());
    try {
      const response = await Api.startPreparation(distributionCenter);
      dispatch(postStartPreparation(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postStartPreparation(err));
    }
  };
}

// Used to transition a specific order
export const postStartPackagingRequest = createAction(
  "POST_START_PACKAGING_REQUEST",
);
export const postStartPackaging = createAction("POST_START_PACKAGING");

export function startPackaging(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(postStartPackagingRequest());
    try {
      const response = await Api.startPackaging(orderNumber);
      dispatch(postStartPackaging(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postStartPackaging(err));
    }
  };
}

// Used to transition the state from packaging
export const postOrderIssueRequest = createAction("POST_ORDER_ISSUE_REQUEST");
export const postOrderIssue = createAction("POST_ORDER_ISSUE");

export function newOrderIssue(issue) {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch(postOrderIssueRequest());
    try {
      const response = await Api.newIssue(issue);
      dispatch(postOrderIssue(response));
      // We should request the active order again to make sure we are up to date
      // NOTE: We request the ACTIVE order. This means that the order can change.
      const orderNumber = getCurrentOrderNumberSelector(getState());
      dispatch(getOrderByNumber(orderNumber));
    } catch (err) {
      logAPIException(err);
      dispatch(postOrderIssue(err));
    }
  };
}

////////////////// SALES XML ///////////////////////////
// Downloads the XML for sales
export const fetchSalesXmlRequest = createAction("FETCH_SALES_XML_REQUEST");
export const fetchSalesXml = createAction("FETCH_SALES_XML");

export function downloadSalesXml(orderNumber: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSalesXmlRequest());
    try {
      // If we succeed we should download the file
      const response = await Api.getSalesXml(orderNumber);

      const blob = new Blob([response], { type: "text/xml" });
      saveAs(blob, `xml_${orderNumber}.xml`);

      dispatch(fetchSalesXml());
    } catch (err) {
      logAPIException(err);
      dispatch(fetchSalesXml(err));
    }
  };
}
