import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Toast.css';

const Toast = (props) => {
  const { title, description, backgroundColor, position } = props;

  return (
    <>
      <div className={`toast-notification-container toast-${position}`}>
        <div
          className={`toast-notification toast toast-${position}`}
          style={{ backgroundColor }}
        >
          <div>
            <p className="toast-notification-title">{title}</p>
            {description && (
              <p className="toast-notification-message">{description}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default Toast;
