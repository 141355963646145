// @flow
import { createSelector } from "reselect";
import type { StoreState } from "../types";

export const thisStateSelector = (state: any): StoreState => {
  return state.weight;
};

export const currentWeightSelector = createSelector(
  thisStateSelector,
  state => state.weight
);

export const gettingWeightLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingWeight
);
