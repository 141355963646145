import axios from 'axios';

const cameraServerAddress = 'http://localhost:8020';

const Camera = {
  async getImage() {
    try {
      const response = await axios.get(
        `${cameraServerAddress}/webcam/capture`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return response;
    } catch (err) {
      console.error(err);
    }
  },
};

export default Camera;
