// @flow
import React, { Component } from "react";
//Types
import Product from "../types";
// Components
import { Link } from "react-router-dom";
// Helpers
import { quantityConverter } from "../../common";
// Styles
import "./ProductItem.css";

const ProductItem = (props) => {
  const product = props.product;

  return (
    <div className="product-item-main">
      <Link to={`/pre-preparation/${product.slug}`}>
        <div className="product-item-main-image">
          <img src={`${product.imageUrl}`} alt="" />
        </div>
        <div className="product-item-main-name">
          <p>{product.name}</p>
        </div>
        <div className="product-item-main-number-of-orders">
          <p>{`${product.totalOrders} pedidos`}</p>
        </div>
        <div className="product-item-data-and-info-content">
          <p>
            {quantityConverter(product.totalQuantity, product.unitForQuantity)}
          </p>
          <span>Pedido</span>
        </div>
        <div className="product-item-starred-symbol">
          {product.starred && <i className="fa fa-star" aria-hidden="true" />}
        </div>
      </Link>
    </div>
  );
};

export default ProductItem;
