// @flow
import React, { Component } from "react";
import _ from "lodash";
// Composers
import { connect } from "react-redux";
import { issueSourcesConnector } from "../issue-sources";
// Components
import Modal from "react-modal";
import { IssueSourcesGetter } from "../issue-sources";
// Selectors
import {
  getCurrentOrderNumberSelector,
  getCurrentOrderLineItemsSelector
} from "../selectors/orders";
// Actions
import { newOrderIssue } from "../actions/orders";
// Types
import type { IssueSource } from "../issue-sources";
// Styles
import "./ErrorModal.css";

type Props = {
  isOpened: boolean,
  lineItemId: ?number,
  orderNumber: ?string,
  closeModal: () => void,

  // From issue sources connector
  issueSources: IssueSource[],
  issueSourcesLoading: boolean,
  issueSourceById: (id: number) => IssueSource
};

type State = {
  selectedError: number
};

class ErrorModal extends Component {
  state: State;
  handleSubmit: (issueSourceId: number, details: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedError: 0
    };
  }

  handleSubmit(issueSourceId: number, details: string) {
    // TODO: Add validation here.

    this.props.handleNewIssue(
      this.props.lineItemId,
      issueSourceId,
      details,
      this.props.orderNumber
    );
    this.props.closeModal();
  }

  render() {
    const activeLineItem = this.props.orderLineItems
      ? _.find(this.props.orderLineItems, { id: this.props.lineItemId })
      : null;

    const customStyles = {
      content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
      }
    };

    return (
      <Modal
        isOpen={this.props.isOpened}
        shouldCloseOnOverlayClick={true}
        contentLabel="Error Selection Modal"
        onRequestClose={this.props.closeModal}
        style={customStyles}
      >
        <div className="error-modal-main">
          {/* TODO: Is this the best place to request the issue sources? */}
          <IssueSourcesGetter />

          <h1 className="error-modal-title">
            Oops! O que há de errado
            {" "}{activeLineItem ? "com " + activeLineItem.name : null}?
          </h1>

          {!this.props.issueSourcesLoading
            ? <div className="error-modal-form">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    this.handleSubmit(
                      this.state.selectedError,
                      this.refs.details.value
                    );
                  }}
                >
                  {this.props.issueSources.map((source, ind) =>
                    <div key={ind}>
                      <input
                        type="radio"
                        className="error-modal-radio-input"
                        checked={this.state.selectedError === source.id}
                        onChange={() =>
                          this.setState({ selectedError: source.id })}
                      />
                      {" "}{source.name}
                    </div>
                  )}

                  <div className="error-modal-details-box">
                    Digite mais detalhes:
                  </div>
                  <textarea
                    className="error-modal-input"
                    autoFocus
                    ref="details"
                  />
                  <div className="error-modal-submit-button">
                    <input
                      type="submit"
                      className="error-modal-button"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            : <div>LOADING!</div>}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderNumber: getCurrentOrderNumberSelector(state),
    orderLineItems: getCurrentOrderLineItemsSelector(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleNewIssue: (lineItemId, issueSourceId, details, orderNumber) => {
      dispatch(
        newOrderIssue({
          lineItemId,
          issueSourceId,
          details,
          orderNumber
        })
      );
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default issueSourcesConnector(connector(ErrorModal));
