// @flow
import { createAction } from "redux-actions";
// Logger
import { logAPIException } from "../../logHelper";
// Apis
import Api from "../api";

////////////////// PRE PREPERATION RELATED ///////////////////////////
// Get Pre prep index
export const fetchPrePreparationProductIndexRequest = createAction(
  "FETCH_PRE_PREPARATION_PRODUCT_INDEX_REQUEST",
);
export const fetchPrePreparationProductIndex = createAction(
  "FETCH_PRE_PREPARATION_PRODUCT_INDEX",
);

export function getPrePreparationProductIndex() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPrePreparationProductIndexRequest());
    try {
      const response = await Api.getPrePreparationIndex();
      dispatch(fetchPrePreparationProductIndex(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchPrePreparationProductIndex(err));
    }
  };
}

// Get Pre prep orders given a specific product
export const fetchPrePreparationOrdersByProductRequest = createAction(
  "FETCH_PRE_PREPARATION_ORDERS_BY_PRODUCT_REQUEST",
);
export const fetchPrePreparationOrdersByProduct = createAction(
  "FETCH_PRE_PREPARATION_ORDERS_BY_PRODUCT",
);

export function getPrePreparationOrdersByProduct(productId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchPrePreparationOrdersByProductRequest());
    try {
      const response = await Api.getPrePreparationOrdersByProduct(productId);
      dispatch(fetchPrePreparationOrdersByProduct(response));
    } catch (err) {
      logAPIException(err);
      dispatch(fetchPrePreparationOrdersByProduct(err));
    }
  };
}

// Updates a line item
export const updateLineItemPrePrepRequest = createAction(
  "UPDATE_LINE_ITEM_PRE_PREP_REQUEST",
);
export const updateLineItemPrePrep = createAction("UPDATE_LINE_ITEM_PRE_PREP");

export function editLineItemPrePrep(updatableLineItem, productId) {
  return async (dispatch: Dispatch, getState: () => any) => {
    dispatch(updateLineItemPrePrepRequest({ itemId: updatableLineItem.id }));
    try {
      const response = await Api.updateLineItem(updatableLineItem);
      dispatch(updateLineItemPrePrep(response));
      // We should request the active order again to make sure we are up to date
      dispatch(getPrePreparationOrdersByProduct(productId));
    } catch (err) {
      logAPIException(err);
      dispatch(updateLineItemPrePrep(err));
    }
  };
}
