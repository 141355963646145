// @flow
import React from 'react';

// Components
import Modal from 'react-modal';
import { Button } from '../common';

// styles
import './TotalPriceLimited.css';

const TotalPriceLimited = ({ isOpened, closeModal }) => {
  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={isOpened}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error Selection Modal"
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div
        className="total-price-limit-container"
        tabIndex="1"
        onKeyDown={() => {
          closeModal();
        }}
      >
        <h1>
          Atenção! Esse pedido não pode ultrapassar o total pré estabelecido{' '}
        </h1>
        <span>
          Verifique novamente se todos os itens foram pesados corretamente, e
          caso esteja correto, informe ao supervisor sobre essa situação.
          Pressione qualquer código ou qualquer tecla para voltar a tela
          anterior.
        </span>
        <input type={'submit'} autoFocus value={'OK'} />
      </div>
    </Modal>
  );
};

export default TotalPriceLimited;
