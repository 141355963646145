// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { StoreState } from "../types/StoreState";

// Main state selector
export const thisStateSelector = (state: any): StoreState => state.employee;

export const loggedInSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loggedIn || false
);

export const idSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.id
);

const employeeInformationSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.information
);

export const employeeUsernameSelector = createSelector(
  thisStateSelector,
  employeeInformationSelector,
  (state: StoreState, information) => information.username
);

// Related to roles
export const employeeRolesSelector = createSelector(
  thisStateSelector,
  employeeInformationSelector,
  (state, information) => information.roles
);

export const employeeRolesNameSelector = createSelector(
  thisStateSelector,
  employeeRolesSelector,
  (state, roles) => roles.map(r => r.name)
);

export const employeeHasRoleSelector = (state, role: string) => {
  const roles = employeeRolesSelector(state);

  return _.findIndex(roles, { slug: role }) > -1;
};

// General Loading Related
export const employeeLoggingInLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.loggingIn
);

export const employeeValidatingLoadingSelector = createSelector(
  thisStateSelector,
  (state: StoreState) => state.loading.validating
);

// Error Related
export const loginErrorsSelector = createSelector(thisStateSelector, state => ({
  badUsername: state.errors.wrongLoginUsername,
  badPassword: state.errors.wrongLoginUsername
}));
