// @flow
import { createAction } from "redux-actions";
// Types
import type { Dispatch } from "../../common";
// Apis
import Employee from "../api";
// Selectors

// Logger
import { logAPIException } from "../../logHelper";

// Login
export const loginEmployeeRequest = createAction("LOGIN_USER_REQUEST");
export const loginEmployee = createAction("LOGIN_USER");

export function login(email: string, password: string) {
  return async (dispatch: Dispatch) => {
    dispatch(loginEmployeeRequest());
    try {
      const userResponse = await Employee.login(email, password);
      dispatch(loginEmployee(userResponse));
    } catch (err) {
      logAPIException(err);
      dispatch(loginEmployee(err));
    }
  };
}

// Logout user
export const logoutEmployeeRequest = createAction("LOGOUT_USER_REQUEST");
export const logoutEmployee = createAction("LOGOUT_USER");

export function logout() {
  return async (dispatch: Dispatch) => {
    dispatch(logoutEmployeeRequest());
    try {
      await Employee.logout();
      dispatch(logoutEmployee());
    } catch (err) {
      // If there's an error logging out, still remove tokens
      logAPIException(err);
      dispatch(logoutEmployee());
    }
  };
}

// Validate token
export const validateEmployeeRequest = createAction("VALIDATE_USER_REQUEST");
export const validateEmployee = createAction("VALIDATE_USER");

export function validate() {
  return async (dispatch: Dispatch) => {
    dispatch(validateEmployeeRequest());
    try {
      const userResponse = await Employee.validateToken();
      dispatch(validateEmployee(userResponse));
    } catch (err) {
      logAPIException(err);
      dispatch(validateEmployee(err));
    }
  };
}
