// @flow
import React, { Component, useState } from "react";
import _ from "lodash";
// Components
import { PortioningSizeItem } from "../common";
import Button from "../common/components/Button";
import ShowAllPreparationImagesModal from "./ShowAllPreparationImagesModal";
// Types
import type { Issue, Mix } from "../types";
import type { PreparationImage } from '../types/PreparationImage';
//Helpers
import blendColorsHelper from "../common/blendColorsHelper";
// Styles
import "./LineItem.css";
// Images
import NoPreparationImages from '../images/noPreparationImages.png';

type Props = {
  imageUrl: string,
  name: string,
  sku: string,
  optionValues: string | [],
  quantityOrdered: number,
  quantityActual: number,
  quantityUnit: string,
  quantityRevised: ?number,
  isComplete: boolean,
  putOnHoldAt: ?string,
  issues: Issue[],
  canEditError: boolean,
  observation: string,
  mix: Mix,
  mixIndex: number,
  enableReset: boolean,
  preparationImages: PreparationImage[],
  handleAddError: () => void,
  handleClear: () => void,
};

const LineItem = (props: Props) => {
    const [showPhotos, setShowPhotos] = useState(false);

    let lineItemColor = "";
    if (
      props.issues.length > 0 &&
      _.find(props.issues, (o) => {
        return !o.discardedAt;
      })
    ) {
      lineItemColor = "line-item-red";
    } else if (props.isComplete) {
      lineItemColor = "line-item-green";
    } else if (props.putOnHoldAt) {
      lineItemColor = "line-item-yellow";
    } else if (props.quantityRevised && props.quantityRevised > 0) {
      lineItemColor = "line-item-yellow";
    }

    return (
      <div className="line-item-main ">
        <ShowAllPreparationImagesModal
          isOpen={showPhotos}
          onRequestClose={() => setShowPhotos(false)}
          images={props.preparationImages}
          lineItemName={props.name}
        />
        <hr className="line-item-line" />
        {props.observation ? (
          <div className={"line-item-observation-div " + lineItemColor}>
            <p className="line-item-observation-header-text">Observação: </p>{" "}
            <p className={"line-item-observation "}>{props.observation}</p>
          </div>
        ) : null}
        <div className={"line-item-flexcontainer " + lineItemColor}>
          <div className="line-item-product-detail-wrapper">
            <img className="line-item-image" src={props.imageUrl} alt="" />

            <div className="line-item-data-container">
              <div className="line-item-title-container">
                <p className="line-item-title">
                  {props.name}
                  {props.mix ? (
                    <span
                      className="line-item-title-mix-name"
                      style={{
                        color: blendColorsHelper.colors[props.mixIndex],
                      }}
                    >{` - ${props.mix.name}`}</span>
                  ) : null}
                  <PortioningSizeItem
                    portioningSize={props.portioningSize}
                    quantityUnit={props.quantityUnit}
                    quantityOrdered={props.quantityOrdered}
                  />
                </p>
              </div>
              <p>
                {props.sku} - {props.optionValues}{" "}
              </p>
            </div>
          </div>
          <div className="line-item-weight-wrapper">
            {props.preparationImages.length > 0 ? (
              <img
                onClick={() => setShowPhotos(true)}
                src={props.preparationImages[props.preparationImages.length - 1].url} //Show the last, most recent photo
                className="line-item-preparation-image"
              />) : ( 
              <img
                src={NoPreparationImages}
                className="line-item-preparation-image line-item-preparation-image-no-image"
              />
            )}
            {!props.canEditError ? (
              <div />
            ) : (
              <div className="line-item-icon-container">
                <div className="line-item-data-container">
                  <i
                    className="fa fa-undo undo-icon"
                    aria-hidden="true"
                    onClick={props.handleClear}
                  />
                </div>
                <div className="line-item-data-container">
                  <i
                    className="fa fa-exclamation-circle error-icon"
                    aria-hidden="true"
                    onClick={props.handleAddError}
                  />
                </div>
              </div>
            )}

            <div className="line-item-data-container">
              <p className="line-item-large-font">
                {props.quantityOrdered} {props.quantityUnit}
              </p>
              <p>Pedido</p>
            </div>

            <div className="line-item-data-container">
              {props.prePrepQuantity === 0 ? (
                <p className="line-item-large-font">--</p>
              ) : (
                <p className="line-item-large-font">
                  {props.prePrepQuantity} {props.quantityUnit}
                </p>
              )}
              <p>Pré processado</p>
            </div>

            <div className="line-item-data-container">
              {props.quantityActual === 0 ? (
                <p className="line-item-large-font">--</p>
              ) : (
                <p className="line-item-large-font">
                  {props.quantityActual} {props.quantityUnit}
                </p>
              )}
              <p>Processado</p>
            </div>

            {props.quantityRevised != null ? (
              <div className="line-item-data-container">
                <p className="line-item-large-font">
                  {props.quantityRevised} {props.quantityUnit}
                </p>
                <p>Revisado</p>
              </div>
            ) : (
              <div />
            )}
            {props.enableReset ? (
              <div className="lineitem-reset-main">
                <Button
                  icon="fa fa-undo"
                  buttonType="button-secondary button-undo-fulfillment"
                  onClick={() => props.handleClear()}
                />
              </div>
            ) : null}
          </div>
        </div>

        {props.issues.length > 0 ? (
          <div className={"line-item-issue-container " + lineItemColor}>
            {props.issues.map((issue, ind) => (
              <p key={ind}>
                <b>{issue.type}::</b> {issue.details} ::{" "}
                {issue.discardedAt && issue.solution
                  ? ` :: Solução: ${issue.solution}`
                  : null}
              </p>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

export default LineItem;
