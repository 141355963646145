// @flow
import React, { Component } from 'react';
import './WeightLimitedIcon';

type Props = {
  showPaymentWarning?: boolean,
};

const IncompletePaymentWarningIcon = (props: Props) => 
     props.showPaymentWarning ? (
      <div className="weight-limited">
        <i
          title="pagamento ainda não efetuado pelo cliente."
        >💸</i>
      </div>
    ) : null;

export default IncompletePaymentWarningIcon;
