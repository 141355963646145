// @flow
import { createSelector } from "reselect";
import _ from "lodash";
import type { State as StoreState } from "../reducers/orders";

//////////// INTERNAL USER TO CONSTRUCT SELECTORS ////////////

// Selects the current reducer from the entire store
const thisStateSelector = (state: any): StoreState => {
  return state.orders;
};

// Map of orders
const orderMapSelector = createSelector(
  thisStateSelector,
  state => state.orders
);

// Map of line items
const lineItemsMapSelector = createSelector(
  thisStateSelector,
  state => state.lineItems
);

// Map of issues
const issuesMapSelector = createSelector(
  thisStateSelector,
  state => state.issues
);

/////////////// ORDER RELATED ////////////////////

// Gets the orders from the current search list
export const currentOrderListSelector = createSelector(
  thisStateSelector,
  orderMapSelector,
  (state, orderMap) =>
    _.sortBy(_.without(state.currentList.map(o => orderMap[o]), undefined), [
      "lastStateChangeAt"
    ])
);

// There is at least one valid order in the current search list
export const hasOrderInCurrentOrderListSelector = createSelector(
  currentOrderListSelector,
  orders => orders.length > 0
);

// Gets an order by number
export const orderByNumberSelector = (
  state: StoreState,
  orderNumber: string
) => {
  const orderMap = orderMapSelector(state);
  return orderMap[orderNumber];
};

// Gets the current order
export const currentOrderSelector = createSelector(
  thisStateSelector,
  orderMapSelector,
  (state, orderMap) => orderMap[state.current]
);

// Returns a boolean for whether we have an active valid order
export const hasOrderSelector = createSelector(
  thisStateSelector,
  currentOrderSelector,
  (state, current) => current != null
);

// Returns the state of the current order
export const getCurrentOrderStateSelector = createSelector(
  thisStateSelector,
  currentOrderSelector,
  (state, order) => (order ? order.state : null)
);

// Returns the number of the current order
export const getCurrentOrderNumberSelector = createSelector(
  thisStateSelector,
  state => state.current
);

// Returns the observations of the current order
export const getCurrentOrderObservationSelector = createSelector(
  thisStateSelector,
  currentOrderSelector,
  (state, order) => (order ? order.observation : null)
);

////////////////// LINE ITEM RELATED //////////////////

// Returns the line items for an order of given order number
export const getLineItemsByOrderNumber = (
  state: StoreState,
  orderNumber: string
) => {
  const order = orderByNumberSelector(state, orderNumber);
  const lineItemMap = lineItemsMapSelector(state);

  // If the order is null or undefined
  if (!order || !order.lineItems) return [];

  const lineItems = order.lineItems.map(l => lineItemMap[l]);

  return _.orderBy(lineItems, ["isProcessed", (l) => l.putOnHoldAt ? l.putOnHoldAt : '', "quantityUnit"]);
};

// Returns the line items for the current active order
export const getCurrentOrderLineItemsSelector = (state: StoreState) => {
  const orderNumber = getCurrentOrderNumberSelector(state);
  return getLineItemsByOrderNumber(state, orderNumber);
};

// Returns the number of line items in the given order
export const getNumberLineItemsByOrderNumberSelector = (
  state: StoreState,
  orderNumber: string
) => {
  const lineItems = getLineItemsByOrderNumber(state, orderNumber);
  return lineItems.length;
};

// Returns the number of line items in the current order
export const getNumberLineItemsCurrentOrderSelector = (state: StoreState) => {
  const orderNumber = getCurrentOrderNumberSelector(state);
  const lineItems = getLineItemsByOrderNumber(state, orderNumber);
  return lineItems.length;
};

// Returns the number of line items in the order that have been processed
export const getNumberProcessedLineItemsByOrderNumberSelector = (
  state: StoreState,
  orderNumber: string
) => {
  const lineItems = getLineItemsByOrderNumber(state, orderNumber);

  return _.filter(lineItems, { isProcessed: true }).length;
};

// Returns the number of line items that have been processed in the current order
export const getNumberProcessedLineItemsCurrentOrderSelector = (
  state: StoreState
) => {
  const thisState = thisStateSelector(state);
  return getNumberProcessedLineItemsByOrderNumberSelector(
    state,
    thisState.current
  );
};

// Are there line items that have not been prepared?
export const currentOrderHasItemsToPrepare = (state: StoreState) => {
  const thisState = thisStateSelector(state);
  const numberItems = getNumberLineItemsByOrderNumberSelector(
    state,
    thisState.current
  );
  const numberProcessedItems = getNumberProcessedLineItemsByOrderNumberSelector(
    state,
    thisState.current
  );

  return numberItems > numberProcessedItems;
};

/////////////////// ISSUE RELATED ///////////////////

// Returns the issues by lineItem
export const issuesByLineItemIdSelector = (state, itemId: ?number) => {
  const lineItem = thisStateSelector(state).lineItems[itemId];
  const issueMap = issuesMapSelector(state);

  if (!lineItem) return [];

  return lineItem.issues.map(i => issueMap[i]);
};

// Returns the issues belonging to the line items of the current order
export const currentOrderIssuesSelector = createSelector(
  thisStateSelector,
  getCurrentOrderLineItemsSelector,
  issuesMapSelector,
  (state, items, issueMap) => {
    const issueKeys = items.reduce(
      (prev, item) => [...prev, ...item.issues],
      []
    );
    return issueKeys.map(key => issueMap[key]);
  }
);

// Returns a boolean whether the current order has issues
export const currentOrderHasIssuesSelector = createSelector(
  thisStateSelector,
  currentOrderIssuesSelector,
  (state, issues) => {
    return (
      issues.length > 0 &&
      _.find(issues, o => {
        return !o.discardedAt;
      })
    );
  }
);

////////////////// LOADING RELATED ////////////////////
export const gettingOrdersLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingOrders
);

export const gettingOrderLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingOrder || state.loading.transitioningQueue
);

export const updatingAnyLineItemLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.updatingLineItem.length > 0
);

export const gettingVersionsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.gettingVersions
);

export const requestingXmlLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.requestingXml
);
