// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

export const issue = new schema.Entity("issues");

export const lineItem = new schema.Entity("lineItems", { issues: [issue] });
export const lineItems = new schema.Array(lineItem);

export const mix = new schema.Entity("mixes",
  { lineItems: [lineItem] },
  { idAttribute: "id", processStrategy: (entity) => camelizeKeys(entity) }
);
export const mixes = new schema.Array(mix);

export const order = new schema.Entity(
  "orders",
  {
    lineItems: [lineItem],
    mixes: [mix]
  },
  { idAttribute: "number", processStrategy: entity => camelizeKeys(entity) }
);
export const orders = new schema.Array(order);
