// @flow
import React, { Component } from "react";
// Composers
import { connect } from "react-redux";
// Selectors
import { loggedInSelector } from "../selectors";
// Actions

// Components
import { Link } from "react-router-dom";
// Types
import type { Connector } from "react-redux";
import type { Dispatch } from "../../common";

type Props = {
  loggedIn: boolean
};

class LoggedInIndicator extends Component {
  props: Props;

  render() {
    if (this.props.loggedIn) {
      return <Link to="/logout">Logout</Link>;
    }
    return <Link to="/login">Login</Link>;
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    loggedIn: loggedInSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: {}) => {
  return {};
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(LoggedInIndicator);
