// @flow
import { authGet, authPut, authPost, authGetSalesXml } from "../common/api";
import humps from "humps";
import qs from "qs";
import _ from "lodash";
import { normalize } from "normalizr";
import * as schema from "./schema/orders";

const Order = {
  async getOrders(searchParams) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let params = _.omitBy(searchParams, v => v === null || v === "");
    params = humps.decamelizeKeys(params);

    const searchString = qs.stringify(params);

    const response = await authGet(`/orders?${searchString}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.orders);
    return normalized;
  },

  async getActiveOrder() {
    const response = await authGet(`/orders/active`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async getOrderByNumber(orderNumber: string) {
    const response = await authGet(`/orders/${orderNumber}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },

  async getOrderVersions(orderNumber: string) {
    // NOTE: This API is NOT normalized!
    const response = await authGet(`/orders/${orderNumber}/versions`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    return data;
  },

  async updateLineItem(updatableLineItem) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let body = _.omitBy(updatableLineItem, v => v === null || v === "");
    body = humps.decamelizeKeys(body);

    const response = await authPut(`/line_items/${updatableLineItem.id}`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.lineItem);
    return normalized;
  },
  // Used to transition the state from preparation
  async finishPreparation(orderNumber: string, internalBoxesCount: number) {
    const body = {
      next_state: "packaging_queue",
      internal_boxes_count: internalBoxesCount
    };
    const response = await authPut(`/orders/${orderNumber}`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  // Used to transition the state from packaging
  async finishPackaging(orderNumber: string, packagedBoxesCount: number) {
    const body = {
      next_state: "ready",
      packaged_boxes_count: packagedBoxesCount
    };
    const response = await authPut(`/orders/${orderNumber}`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  async startPreparation(distributionCenter: string) {
    const body = { distribution_center: distributionCenter };
    const response = await authPost(`/orders/preparation`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  async startPackaging(orderNumber: string) {
    const body = {};
    const response = await authPut(`/orders/${orderNumber}/packaging`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  async newIssue(issue) {
    const body = {
      line_item_id: issue.lineItemId,
      issue_source_id: issue.issueSourceId,
      details: issue.details,
      order_id: issue.orderNumber
    };
    const response = await authPost(`/issues`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.issue);
    return normalized;
  },
  ////////////// RELATED TO SALES XML FOR NFE ////////////////
  async getSalesXml(orderNumber: string) {
    const response = await authGetSalesXml(
      `/api/v1/orders/${orderNumber}/receipt`
    );
    const data = await response.text().then();
    return data;
  }
};

export default Order;
