// @flow
import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";
import _ from "lodash";
// Types
import type { LineItem, Issue, OrderVersion, NormalizedOrder } from "../types";
// Actions
import {
  fetchOrdersRequest,
  fetchOrders,
  fetchOrderByNumberRequest,
  fetchOrderByNumber,
  fetchOrderVersionsRequest,
  fetchOrderVersions,
  updateLineItemRequest,
  updateLineItem,
  updateFinishPreparationRequest,
  updateFinishPreparation,
  updateFinishPackagingRequest,
  updateFinishPackaging,
  postStartPreparationRequest,
  postStartPreparation,
  postStartPackagingRequest,
  postStartPackaging,
  orderClear,
  fetchSalesXmlRequest,
  fetchSalesXml
} from "../actions/orders";

////////////////////////////// CURRENT LIST REDUCER
// Keys of the orders in the current search
const currentListInitialState = [];
const currentList = handleActions(
  {
    [combineActions(fetchOrders)]: {
      next: (state, action) => [...action.payload.result]
    }
  },
  currentListInitialState
);

////////////////////////////// ORDER NUMBER REDUCER
// Stores order number for the currently active order
const currentInitialState = null;
const current = handleActions(
  {
    [combineActions(
      fetchOrderByNumber,
      postStartPreparation,
      postStartPackaging
    )]: { next: (state, action) => action.payload.result },
    [orderClear]: (state, action) => currentInitialState
  },
  currentInitialState
);

////////////////////////////// ORDERS REDUCER
// Stores the information order map
const ordersInitialState: Map<string, NormalizedOrder> = {};
const orders = handleActions(
  {
    [combineActions(
      fetchOrders,
      fetchOrderByNumber,
      postStartPreparation,
      postStartPackaging
    )]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.orders })
    }
  },
  ordersInitialState
);

////////////////////////////// LINE ITEMS REDUCER
// Stores order number for the currently active order
const lineItemsInitialState: Map<number, LineItem> = {};
const lineItems = handleActions(
  {
    [combineActions(
      fetchOrders,
      fetchOrderByNumber,
      postStartPreparation,
      postStartPackaging,
      updateLineItem
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.lineItems
      })
    }
  },
  lineItemsInitialState
);

////////////////////////////// ISSUES REDUCER
// Possible issues for the products
const issuesInitialState = {};
const issues = handleActions(
  {
    [combineActions(
      fetchOrders,
      fetchOrderByNumber,
      postStartPreparation,
      updateLineItem,
      postStartPackaging
    )]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.issues })
    }
  },
  issuesInitialState
);

////////////////////////////// VERSIONS REDUCER
// Stores the versions of this order. Note that this will usually not be updated together with the order
const versionsInitialState = {};
const versions = handleActions(
  {
    [fetchOrderVersions]: {
      next: (state, action) => ({
        ...state,
        [action.payload.orderNumber]: action.payload.versions
      })
    }
  },
  versionsInitialState
);

////////////////////////////// LOADING REDUCER
type Loading = {
  gettingOrder: boolean,
  gettingOrders: boolean,
  updatingOrderState: boolean,
  gettingVersions: boolean,
  transitioningQueue: boolean,
  updatingLineItem: [], // Array of IDs that are being updated
  requestingXml: boolean
};
const loadingInitialState = {
  gettingOrder: false,
  gettingOrders: false,
  updatingOrderState: false,
  gettingVersions: false,
  transitioningQueue: false,
  updatingLineItem: [],
  requestingXml: false
};
const loading = handleActions(
  {
    [combineActions(fetchOrdersRequest)]: (state, action) => ({
      ...state,
      gettingOrders: true
    }),
    [combineActions(fetchOrders)]: (state, action) => ({
      ...state,
      gettingOrders: false
    }),
    [combineActions(fetchOrderByNumberRequest)]: (state, action) => ({
      ...state,
      gettingOrder: true
    }),
    [combineActions(fetchOrderByNumber)]: (state, action) => ({
      ...state,
      gettingOrder: false
    }),
    [combineActions(postStartPreparationRequest, postStartPackagingRequest)]: (
      state,
      action
    ) => ({
      ...state,
      transitioningQueue: true
    }),
    [combineActions(postStartPreparation, postStartPackaging)]: (
      state,
      action
    ) => ({
      ...state,
      transitioningQueue: false
    }),
    [fetchOrderVersionsRequest]: (state, action) => ({
      ...state,
      gettingVersions: true
    }),
    [fetchOrderVersions]: (state, action) => ({
      ...state,
      gettingVersions: false
    }),
    [combineActions(
      updateFinishPreparationRequest,
      updateFinishPackagingRequest
    )]: (state, action) => ({
      ...state,
      updatingOrderState: true
    }),
    [combineActions(updateFinishPreparation, updateFinishPackaging)]: (
      state,
      action
    ) => ({
      ...state,
      updatingOrderState: false
    }),
    [updateLineItemRequest]: (state, action) => ({
      ...state,
      updatingLineItem: [...state.updatingLineItem, action.payload.itemId]
    }),
    [updateLineItem]: (state, action) => ({
      ...state,
      updatingLineItem: _.filter(state.updatingLineItem, action.payload.result)
    }),
    [fetchSalesXmlRequest]: (state, action) => ({
      ...state,
      requestingXml: true
    }),
    [fetchSalesXml]: (state, action) => ({
      ...state,
      requestingXml: false
    })
  },
  loadingInitialState
);

///////////////////////////// COMBINE REDUCER
type State = {
  currentList: string[],
  current: ?string,
  orders: Map<string, NormalizedOrder>,
  lineItems: [],
  issues: Map<number, Issue>,
  versions: OrderVersion[],

  loading: Loading
};

const order: State = combineReducers({
  currentList,
  current,
  orders,
  lineItems,
  issues,
  versions,

  loading
});

export default order;
