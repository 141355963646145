// @flow
import React, { Component } from "react";
import "./Footer.css";
// Composers
import { connect } from "react-redux";
import { employeeConnector } from "../employee";
// Components
import BarcodeInput from "../components/BarcodeInput";
import TimerCountdown from "./TimerCountdown";
import StatePresentationName from "./StatePresentationName";
// Selectors
import {
  getCurrentOrderNumberSelector,
  gettingOrderLoadingSelector,
  getCurrentOrderStateSelector,
  getNumberLineItemsCurrentOrderSelector,
  getNumberProcessedLineItemsCurrentOrderSelector
} from "../selectors/orders.js";
// Types
import type { OrderAasm } from "../types";

type Props = {
  handleSubmit: (barcode: string) => void,
  counterStartTime: ?number,
  // From connector
  orderNumber: string,
  numberOfProcessedLineItems: number,
  numberOfLineItems: number,
  orderAasm: OrderAasm,
  // From employee connector
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeHasRole: (role: string) => boolean
};

class Footer extends Component {
  props: Props;

  render() {
    return (
      <div className="footer-main">
        <hr className="footer-line" />
        <div className="footer-flexcontainer">
          <div>
            <p className="footer-order-number">
              {this.props.orderNumber ? "#" + this.props.orderNumber : "--"}
            </p>
            <p>
              {this.props.numberOfLineItems
                ? this.props.numberOfProcessedLineItems +
                  " de " +
                  this.props.numberOfLineItems
                : null}{" "}
            </p>
          </div>

          <div className="footer-time-counter">
            <TimerCountdown
              countStartValue={this.props.counterStartTime || 0}
            />
            <p>
              <StatePresentationName state={this.props.orderAasm} />
            </p>
          </div>

          <BarcodeInput handleSubmit={this.props.handleSubmit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderNumber: getCurrentOrderNumberSelector(state),
    isGettingOrder: gettingOrderLoadingSelector(state),
    orderAasm: getCurrentOrderStateSelector(state),
    numberOfLineItems: getNumberLineItemsCurrentOrderSelector(state),
    numberOfProcessedLineItems: getNumberProcessedLineItemsCurrentOrderSelector(
      state
    )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default employeeConnector(connector(Footer));
