// @flow
import React, { Component } from "react";
// Style
import "./PortioningSizeItem.css";

type Props = {
  portioningSize: number,
  quantityUnit: string,
  quantityOrdered: number
};

class PortioningSizeItem extends Component {
  props: Props;

  render() {
    const portionCount = parseInt(
      this.props.quantityOrdered / this.props.portioningSize,
      10
    );
    const portionResidue =
      this.props.quantityOrdered % this.props.portioningSize;

    if (this.props.portioningSize) {
      return (
        <span className="active-line-item-portioning-size">
          ({portionCount}x {this.props.portioningSize}
          {this.props.quantityUnit}
          {portionResidue
            ? `, 1x ${parseInt(portionResidue, 10)}${this.props.quantityUnit}`
            : ""})
        </span>
      );
    } else {
      return null;
    }
  }
}

export default PortioningSizeItem;
