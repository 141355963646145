// @flow
import React from "react";
// Components
import { Button, InputFieldErrorMessage } from "../../common";
// Styles
import "./LoginBox.css";

type State = {
  badUsername: string,
  badPassword: string,
  badBarcode: string,
};

type Props = {
  isLoading: boolean,
  errors: {
    badUsername: boolean,
    badPassword: boolean
  },
  onSubmitLogin: () => void
};

class LoginBox extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      badUsername: "",
      badPassword: "",
      badBarcode: "",
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput(username: string, password: string, barcode: string) {
    let noValidationErrors: boolean = true;

    // If data was input into the barcode field
    if (barcode) {
      const splitBarcode = barcode.split(",");

      if (noValidationErrors) {
        this.props.onSubmitLogin(splitBarcode[0], splitBarcode[1]);
      } else {
        console.error("Validation error on registration form.");
      }
    } else {
      if (username === "") {
        // First name is missing or invalid? TODO: Invalid part
        this.setState({ badUsername: "Campo é obrigatório" });
        noValidationErrors = false;
      } else {
        this.setState({ badUsername: "" });
      }

      if (password === "") {
        // Last name is missing or invalid? TODO: Invalid part
        this.setState({ badPassword: "Campo é obrigatório" });
        noValidationErrors = false;
      } else {
        this.setState({ badPassword: "" });
      }

      if (noValidationErrors) {
        this.props.onSubmitLogin(username, password);
      } else {
        console.error("Validation error on registration form.");
      }
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    // If we have a server error show them instead of the local errors
    if (nextProps.errors.badUsername) {
      this.setState({ badUsername: "Usuário ou senha incorretos", badBarcode: "Código de barras incorreto" });
    }

    if (nextProps.errors.badPassword) {
      this.setState({ badPassword: "Usuário ou senha incorretos", badBarcode: "Código de barras incorreto" });
    }
  }

  render() {
    return (
      <div className="login-box-main">
        <h3>Login</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.validateUserInput(
              this.refs.email.value,
              this.refs.password.value,
              this.refs.barcode.value
            );
          }}
        >
          <h3>Digite usuario e senha</h3>
          <InputFieldErrorMessage message={this.state.badUsername} />
          <input
            ref="email"
            type="text"
            className="login-box-input"
            placeholder="Email"
          />

          <InputFieldErrorMessage message={this.state.badPassword} />
          <input
            ref="password"
            type="password"
            className="login-box-input"
            placeholder="Senha"
          />

          <h3>Ou escaneie código de barras</h3>
          <InputFieldErrorMessage message={this.state.badBarcode} />
          <input
            ref="barcode"
            type="password"
            className="login-box-input"
            placeholder="Código de barras"
            autoFocus={true}
          />

          <div className="login-box-button">
            <Button
              text="ENTRAR"
              buttonType="button-primary"
              loading={this.props.isLoading}
              onClick={() => { }}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default LoginBox;
