import React, { Component } from "react";
//Components
import { PortioningSizeItem } from "../common";
//Helpers
import blendColorsHelper from "../common/blendColorsHelper";
//Style
import "./BlendInfo.css";
type Props = {
  mix: Mix,
  orderObservation: string,
  name: string,
  quantity: string,
  portioningSize: string,
  fatPercentage: string,
  key: number,
  id: number,
};

class BlendInfo extends Component {
  props: Props;
  render() {
    const {
      observation,
      name,
      quantity,
      portioningSize,
      fatPercentage,
    } = this.props.mix;
    const index = this.props.id;
    return (
      <li key={index} className="blend-info-main">
        <div className="blend-info-content">
          {observation ? (
            <div>
              <h3>
                {`Observações `}
                <span
                  style={{ color: blendColorsHelper.colors[index] }}
                >{`${name}: `}</span>
                <span className="observation-text">{observation}</span>
              </h3>
            </div>
          ) : null}
          {quantity || portioningSize || fatPercentage ? <p>::</p> : null}
          {quantity ? (
            <div>
              <h4>
                {` Quantidade: `}
                <span>{`${quantity} g`}</span>
              </h4>
            </div>
          ) : null}
          <p>&nbsp;-&nbsp;</p>
          <div>
            <h4>
              {` Gordura: `}
              {fatPercentage ? (
                <span>{`${fatPercentage}% (${
                  (quantity / 100) * fatPercentage
                } g)`}</span>
              ) : (
                <span>{`Não definido`}</span>
              )}
            </h4>
          </div>
          {(quantity || fatPercentage) && portioningSize ? (
            <p>&nbsp;-&nbsp;</p>
          ) : null}
          {portioningSize ? (
            <div>
              <h4>
                {` Porcionamento:`}
                <span>
                  <PortioningSizeItem
                    portioningSize={portioningSize}
                    quantityUnit={"g"}
                    quantityOrdered={quantity}
                  />
                </span>
              </h4>
            </div>
          ) : null}
          {quantity || portioningSize || fatPercentage ? <p>::</p> : null}
        </div>
      </li>
    );
  }
}

export default BlendInfo;
