// @flow
import React, { Component } from "react";
import _ from "lodash";
// Components
import { PortioningSizeItem } from "../../common";
import Button from "../../common/components/Button";
// Types
import type { Issue, Mix } from "../../types";
//Helpers
import blendColorsHelper from "../../common/blendColorsHelper";
// Styles
import "./LineItem.css";

type Props = {
  imageUrl: string,
  name: string,
  sku: string,
  optionValues: string | [],
  quantityOrdered: number,
  mixPreWeightedQuantity: number,
  prePrepQuantity: number,
  isInPrePrep: boolean,
  quantityUnit: string,
  isComplete: boolean,
  putOnHoldAt: ?string,
  issues: Issue[],
  canEditError: boolean,
  observation: string,
  mix: Mix,
  mixIndex: number,
  enableReset: boolean,
  handleAddError: () => void,
  handleClear: () => void,
};

class LineItem extends Component {
  props: Props;

  render() {
    let lineItemColor = "";
    if (
      this.props.issues.length > 0 &&
      _.find(this.props.issues, (o) => {
        return !o.discardedAt;
      })
    ) {
      lineItemColor = "blend-line-item-red";
    } else if (this.props.isComplete && this.props.prePrepQuantity && this.props.prePrepQuantity > 0) {
      lineItemColor = "blend-line-item-green";
    } else if (this.props.putOnHoldAt) {
      lineItemColor = "blend-line-item-yellow";
    } else if (this.props.mixPreWeightedQuantity && this.props.mixPreWeightedQuantity > 0) {
      lineItemColor = "blend-line-item-yellow";
    }

    return (
      <div className="blend-line-item-main ">
        <hr className="blend-line-item-line" />
        {this.props.observation ? (
          <div className={"blend-line-item-observation-div " + lineItemColor}>
            <p className="blend-line-item-observation-header-text">Observação: </p>{" "}
            <p className={"blend-line-item-observation "}>{this.props.observation}</p>
          </div>
        ) : null}
        <div className={"blend-line-item-flexcontainer " + lineItemColor}>
          <div className="blend-line-item-product-detail-wrapper">
            <img className="blend-line-item-image" src={this.props.imageUrl} alt="" />

            <div className="blend-line-item-data-container">
              <div className="blend-line-item-title-container">
                <p className="blend-line-item-title">
                  {this.props.name}
                  {this.props.mix ? (
                    <span
                      className="blend-line-item-title-mix-name"
                      style={{
                        color: blendColorsHelper.colors[this.props.mixIndex],
                      }}
                    >{` - ${this.props.mix.name}`}</span>
                  ) : null}
                  <PortioningSizeItem
                    portioningSize={this.props.portioningSize}
                    quantityUnit={this.props.quantityUnit}
                    quantityOrdered={this.props.quantityOrdered}
                  />
                </p>
              </div>
              <p>
                {this.props.sku} - {this.props.optionValues}{" "}
              </p>
            </div>
          </div>
          <div className="blend-line-item-weight-wrapper">
            {!this.props.canEditError ? (
              <div />
            ) : (
              <div className="blend-line-item-icon-container">
                <div className="blend-line-item-data-container">
                  <i
                    className="fa fa-undo blend-undo-icon"
                    aria-hidden="true"
                    onClick={this.props.handleClear}
                  />
                </div>
                <div className="blend-line-item-data-container">
                  <i
                    className="fa fa-exclamation-circle blend-error-icon"
                    aria-hidden="true"
                    onClick={this.props.handleAddError}
                  />
                </div>
              </div>
            )}

            <div className="blend-line-item-data-container">
              <p className="blend-line-item-large-font">
                {this.props.quantityOrdered} {this.props.quantityUnit}
              </p>
              <p>Pedido</p>
            </div>

            <div className="blend-line-item-data-container">
              {this.props.mixPreWeightedQuantity === 0 ? (
                <p className="blend-line-item-large-font">--</p>
              ) : (
                <p className="blend-line-item-large-font">
                  {this.props.mixPreWeightedQuantity} {this.props.quantityUnit}
                </p>
              )}
              <p>Pré-processado</p>
            </div>

            <div className="blend-line-item-data-container">
              {this.props.prePrepQuantity === 0 ? (
                <p className="blend-line-item-large-font">--</p>
              ) : (
                <p className="blend-line-item-large-font">
                  {this.props.prePrepQuantity} {this.props.quantityUnit}
                </p>
              )}
              <p>Processado</p>
            </div>

            {this.props.enableReset ? (
              <div className="lineitem-reset-main">
                <Button
                  icon="fa fa-undo"
                  buttonType="button-secondary button-undo-fulfillment"
                  onClick={() => this.props.handleClear()}
                />
              </div>
            ) : null}
          </div>
        </div>

        {this.props.issues.length > 0 ? (
          <div className={"blend-line-item-issue-container " + lineItemColor}>
            {this.props.issues.map((issue, ind) => (
              <p key={ind}>
                <b>{issue.type}::</b> {issue.details} ::{" "}
                {issue.discardedAt && issue.solution
                  ? ` :: Solução: ${issue.solution}`
                  : null}
              </p>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default LineItem;
