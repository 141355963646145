// @flow
import { createAction } from "redux-actions";
// Apis
import Api from "../api";
// Logger
import { logAPIException } from "../../logHelper";

export const postPrintProductLabelRequest = createAction(
  "POST_PRINT_PRODUCT_LABEL_REQUEST",
);
export const postPrintProductLabel = createAction("POST_PRINT_PRODUCT_LABEL");

export function printProductLabel(labelParams) {
  return async (dispatch: Dispatch) => {
    dispatch(postPrintProductLabelRequest());
    try {
      const response = await Api.printProductLabel(labelParams);
      dispatch(postPrintProductLabel(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postPrintProductLabel(err));
    }
  };
}

export const postPrintOrderLabelRequest = createAction(
  "POST_PRINT_ORDER_LABEL_REQUEST",
);
export const postPrintOrderLabel = createAction("POST_PRINT_ORDER_LABEL");

export function printOrderLabel(labelParams) {
  return async (dispatch: Dispatch) => {
    dispatch(postPrintOrderLabelRequest());
    try {
      const response = await Api.printOrderLabel(labelParams);
      dispatch(postPrintOrderLabel(response));
    } catch (err) {
      logAPIException(err);
      dispatch(postPrintOrderLabel(err));
    }
  };
}
