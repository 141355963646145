// @flow
import { createAction } from "redux-actions";
// Types
import type { Dispatch } from "../../common";
// Apis
import Api from "../api";
// Selectors

// Logger
import { logAPIException } from "../../logHelper";

// Saves the distribution center
export const setDistributionCenterRequest = createAction(
  "SET_DISTRIBUTION_CENTER_REQUEST",
);
export const setDistributionCenter = createAction("SET_DISTRIBUTION_CENTER");

export function saveDistributionCenter(distributionCenter: string) {
  return async (dispatch: Dispatch) => {
    dispatch(setDistributionCenterRequest());
    try {
      const response = await Api.setDistributionCenter(distributionCenter);
      dispatch(setDistributionCenter(response));
    } catch (err) {
      logAPIException(err);
      dispatch(setDistributionCenter(err));
    }
  };
}

// Gets the distribution center
export const getDistributionCenterRequest = createAction(
  "GET_DISTRIBUTION_CENTER_REQUEST",
);
export const getDistributionCenter = createAction("GET_DISTRIBUTION_CENTER");

export function fetchDistributionCenter() {
  return async (dispatch: Dispatch) => {
    dispatch(getDistributionCenterRequest());
    try {
      const response = await Api.getDistributionCenter();
      dispatch(getDistributionCenter(response));
    } catch (err) {
      logAPIException(err);
      dispatch(getDistributionCenter(err));
    }
  };
}
