// @flow
import React from "react";
import qs from "qs";
// Components
import { Redirect } from "react-router-dom";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Import Actions
import { logout } from "../actions";
// Selectors
import { loggedInSelector } from "../selectors";
// Types
import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "../../common";

type RouterProps = ContextRouter;
type Props = { loggedIn: boolean } & RouterProps;

class LogoutPage extends React.Component {
  constructor(props: Props) {
    super(props);

    props.handleLogout();
  }

  render() {
    if (!this.props.loggedIn) {
      const params = qs.parse(
        this.props.location.search ? this.props.location.search.substr(1) : ""
      );

      return <Redirect to={params.to ? `/login?to=${params.to}` : "/login"} />;
    }

    return <div>LOGGING OUT</div>;
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleLogout: () => {
      dispatch(logout());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
);
