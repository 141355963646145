import runtimeEnv from '@avantsoft/js-runtime-env';
const env = runtimeEnv();

import { helpers } from '../common';
import { authPost } from '../common/api';

import { imageUpload } from './requests';

const SALES_ENDPOINT =
  env.REACT_APP_SALES_API_URL ||
  'https://sales-backend.bigdelivery-staging.avantsoft.com.br';

const api = {
  async postImageMetadata(image: string, checksum: string) {
    const fileSize = helpers.getImageFileSize(image);
    const body = { file_size: fileSize, checksum };

    if (fileSize && checksum) {
      const response = await authPost(
        '/v2/attachments/fulfillment_item_image/upload_url',
        body,
        SALES_ENDPOINT,
      );

      const data = await response.json();
      return data;
    }
    return null;
  },

  async postImage(signed_url: string, photo: string) {
    if (signed_url && photo) {
      const response = await imageUpload(signed_url, photo);
      return { status: 'ok' };
    }
    return { status: 'error', info: { signed_url, photo } };
  },

  async postImageAttachment(
    signedId: string,
    lineItemId: string,
    attachableType: string,
    attachmentType: string,
  ) {
    const body = {
      signed_id: signedId,
      attachable_id: lineItemId,
      attachable_type: attachableType,
      attachment_type: attachmentType,
    };
    await authPost('/v2/attachments', body, SALES_ENDPOINT);
  },
};
export default api;
