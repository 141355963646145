// @flow
import { combineReducers } from "redux";

import weight from "../weight";
import employee from "../employee";
import orders from "./orders";
import issueSources from "../issue-sources";
import configuration from "../configurationModule";
import prePreparations from "../pre-preparation"
import blendPrePreparations from "../blend-pre-preparation"

const rootReducer = combineReducers({
  employee,
  orders,
  weight,
  issueSources,
  configuration,
  prePreparations,
  blendPrePreparations
});

export type State = typeof rootReducer;

export default rootReducer;
