// @flow
import React, { Component } from 'react';
// Types
import type { OrderAasm } from '../types';
import './StatePresentationName.css';

type Props = {
  state: OrderAasm,
};

class StatePresentationName extends Component {
  props: Props;

  render() {
    if (this.props.state === 'pending') {
      return <span>Aguardando aprovação</span>;
    } else if (this.props.state === 'preparation_queue') {
      return <span>Fila de preparação</span>;
    } else if (this.props.state === 'preparation') {
      return <span>Preparando</span>;
    } else if (this.props.state === 'packaging_queue') {
      return <span>Fila de empacotamento</span>;
    } else if (this.props.state === 'packaging') {
      return <span>Empacotando</span>;
    } else if (this.props.state === 'ready') {
      return <span>Pronto</span>;
    } else if (this.props.state === 'return_queue') {
      return <span>Fila de devolução</span>;
    } else if (this.props.state === 'disassembly') {
      return <span>Devolução</span>;
    } else if (this.props.state === 'cancelled') {
      return <span>Cancelado</span>;
    } else if (this.props.state === 'in_storage') {
      return <span>Em estoque</span>;
    } else if (this.props.state === 'payment_evaluating') {
      return <span>Pagamento sendo efetuado</span>;
    } else if (this.props.state === 'error') {
      return <span className="state-presentation-name">Erro</span>;
    } else {
      return <span>{this.props.state}</span>;
    }
  }
}

export default StatePresentationName;
