import React from 'react';
//Components
import WeightLimitedIcon from '../components/WeightLimitedIcon';
import PrintSaleReceiptIcon from '../components/PrintSaleReceiptIcon';
import BlendInfo from '../components/BlendInfo';
import IncompletePaymentWarningIcon from './IncompletePaymentWarningIcon';

//Style
import './OptionsComponent.css';

const OptionsComponent = (props) => {
    return (
      <div className="options">
        <div className="observation-fields">
          {props.orderObservation ? (
            <h3>
              Observações:{' '}
              <span className="observation-text">
                {props.orderObservation}
              </span>
            </h3>
          ) : null}
          {props.mixes.map((mix, index) =>
            mix ? <BlendInfo mix={mix} key={index} id={index} /> : '',
          )}
        </div>
        <PrintSaleReceiptIcon
          shouldPrintReceipt={props.shouldPrintSaleReceipt}
        />
        <WeightLimitedIcon weightLimited={props.weightLimited} />
        <IncompletePaymentWarningIcon
          showPaymentWarning={props.showPaymentWarning}
        />
      </div>
    );
}

export default OptionsComponent;
