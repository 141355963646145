// @flow
import { schema } from "normalizr";
import { camelizeKeys } from "humps";

export const lineItem = new schema.Entity(
  "lineItem",
  {},
  { idAttribute: "id", processStrategy: (entity) => camelizeKeys(entity) }
);

export const order = new schema.Entity(
  "orders",
  {},
  {
    idAttribute: "number",
    processStrategy: (entity) => camelizeKeys(entity),
  }
);

export const product = new schema.Entity(
  "products",
  {
    orders: [order],
  },
  { idAttribute: "slug", processStrategy: (entity) => camelizeKeys(entity) }
);
export const products = new schema.Array(product);
