// @flow
import { createSelector } from "reselect";
import _ from "lodash";
// Types
import type { State as StoreState } from "../reducers/orders";

const thisStateSelector = (state: any): StoreState => {
  return state.prePreparations;
};

//////////// MAPS ////////////
const productsMapSelector = createSelector(
  thisStateSelector,
  (state) => state.products
);

const ordersMapSelector = createSelector(
  thisStateSelector,
  (state) => state.orders
);

const loadingMapSelector = createSelector(
  thisStateSelector,
  (state) => state.loadings
);

/////////////// PRE PREPATION INDEX RELATED ////////////////////
// Gets the list of all pre preparation index
export const currentPrePreparationsSelector = createSelector(
  thisStateSelector,
  productsMapSelector,
  (state, productsMap) =>
    state.currentProductList.map((slug) => productsMap[slug])
);

/////////////// PRE PREPATION PRODUCT RELATED ////////////////////
// Get all the orders for a given product
export const currentOrdersByProductSelector = (state, productId: string) => {
  const product = productsMapSelector(state)[productId];
  const ordersMap = ordersMapSelector(state);

  if (!product || !product.orders) {
    return [];
  }

  return product.orders.map((orderId: string) => ordersMap[orderId]);
};
//Get the product based on the slug
export const currentProductSelector = (state, productId: string) => {
  const product = productsMapSelector(state)[productId];
  if (!product) return {};
  return product;
};

// Returns the number of line items in the given order
export const getNumberOrdersByProductSelector = (
  state: StoreState,
  productId: string
) => {
  const orders = currentOrdersByProductSelector(state, productId);
  return orders.length;
};

// Returns the number of line items in the order that have been processed
export const getNumberProcessedOrdersByProductSelector = (
  state: StoreState,
  productId: string
) => {
  const orders = getNumberOrdersByProductSelector(state, productId);

  return _.filter(orders, (order) => {
    order.lineItem.isPrePrepComplete === true;
  }).length;
};

export const currentProductHasOrdersToPrepare = (
  state: StoreState,
  productId: string
) => {
  const thisState = thisStateSelector(state);
  const numberOrders = getNumberOrdersByProductSelector(state, productId);
  const numberProcessedOrders = getNumberProcessedOrdersByProductSelector(
    state,
    productId
  );
  return numberOrders > numberProcessedOrders;
};

////////////////// LOADING RELATED ////////////////////
export const gettingProductsLoadingSelector = createSelector(
  thisStateSelector,
  (state) => state.loading.gettingPrePreparationIndex
);
