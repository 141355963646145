// @flow
import React, { Component } from "react";
import _ from "lodash";
// Composers
import { connect } from "react-redux";
import { employeeConnector } from "../employee";
import { distributionCenterConnector } from "../configurationModule";
// Components
import { Link } from "react-router-dom";
// Selectors
import "../selectors/orders.js";
// Types

// Styles
import "./Header.css";

type Props = {
  // From employee connector
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeRoles: string[],
  employeeHasRole: (role: string) => boolean,
  // From distribution center connector
  distributionCenter: string,
  distributionCenterLoading: boolean
};

class Header extends Component {
  props: Props;

  render() {
    let currentPage = this.props.location.pathname.split("/");
    currentPage = currentPage[currentPage.length - 1];
    return (
      <div className="header-main">
        <h3>
          Estação Aberta -{" "}
          {this.props.distributionCenter
            ? _.upperFirst(this.props.distributionCenter)
            : "DESCONFIGURADA"}{" "}
          <span className="header-version-information">
            (Versão: {process.env.REACT_APP_BUILD_COMMIT})
          </span>
        </h3>
        <div>
          Usuário: {this.props.employeeUsername}
          <div>
            {this.props.employeeRoles.map((r, ind) => (
              <span key={ind}>
                {r}
                {", "}
              </span>
            ))}
          </div>
        </div>
        <Link to={`/logout?to=${currentPage}`}>Fechar estação</Link>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default distributionCenterConnector(
  employeeConnector(connector(Header))
);
