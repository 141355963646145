// @flow
import React, { Component } from "react";
// Connectors
import { connect } from "react-redux";
import { weightConnector } from "../../weight";
import { printerConnector } from "../../printer";
// Components
import ProductHeaderInfo from "../components/ProductHeaderInfo";
import ActiveLineOrder from "../components/ActiveLineOrder";
import LineOrder from "../components/LineOrder";
import Footer from "../../components/Footer";
import colorBarcodes from "../../constants/colorBarcodes";
import CompletionModal from "../../components/CompletionModal";
// Actions, Selectors
import * as actions from "../actions";
import * as selectors from "../selectors";
// Styles
import "./PrePreparationIndexPage.css";

type Props = {
  // From weight connector
  currentWeight: number,
  handlePrintOrderLabel: ({ number: string, numberOfBoxes: number }) => void,
};

class PrePreparationProductPage extends Component {
  constructor(props: Props) {
    super(props);

    this.state = {
      completionModalIsOpen: false,
      orderNumber: "",
      hasIssues: false,
      lineItemId: "",
    };
  }
  componentDidMount() {
    this.props.handleGetPrePreparationOrdersByProduct(
      this.props.match.params.productSlug
    );
  }

  handleRefreshPage() {
    this.props.handleGetPrePreparationOrdersByProduct(
      this.props.match.params.productSlug
    );
  }

  processBarcode(barcode: string) {
    console.log(barcode); // FOR DEBUGGING! REMOVE.

    const activeLineOrder = this.props.orders[0]; // TODO: What happens where there is not active line item? Won't this break?
    if (this.props.hasOrdersToPrepare) {
      // ERASE lineOrder quantity, etc
      if (barcode === colorBarcodes.red) {
        this.props.handleOrderUpdateLineItem({
          id: activeLineOrder.lineItem.id,
          prePrepQuantity: 0,
          isInPrePrep: false,
        });
        return;
        // ADD to lineItem quantity, etc
        // Adds the current weight on the scale and increments the packge count
      } else if (barcode === colorBarcodes.yellow) {
        const newWeight =
          activeLineOrder.lineItem.prePrepQuantity +
          (activeLineOrder.lineItem.quantityUnit === "g"
            ? this.props.currentWeight
            : 1);
        this.props.handleOrderUpdateLineItem({
          id: activeLineOrder.lineItem.id,
          prePrepQuantity: newWeight, // Adds scale weight only if it is a weighable product
          isInPrePrep: true,
        });
        this.props.handlePrintProductLabel({
          id: activeLineOrder.lineItem.id,
          name: activeLineOrder.lineItem.name,
          sku: activeLineOrder.lineItem.sku,
          quantity:
            activeLineOrder.lineItem.quantityUnit === "g"
              ? this.props.currentWeight
              : 1,
          unit: activeLineOrder.lineItem.quantityUnit,
        }); // Send command to print
        return;
        // DONE mark this line item as complete so we can move on to the next one
      } else if (barcode === colorBarcodes.green) {
        this.setState({
          completionModalIsOpen: true,
          orderNumber: activeLineOrder.number,
          lineItemId: activeLineOrder.lineItem.id,
          hasIssues: activeLineOrder.lineItem.issues.length,
        });
        return;

        // CUSTOM a preprinted product barcode has been scanned
      } else if (barcode[0] === "2") {
        // Barcode from weighing scale CONTROL CODE 2
        // Check if the sku matches that of the current product
        const barcodeSku = barcode.slice(1, 7);
        const barcodeQuantity = parseInt(barcode.slice(7, 12), 10);

        if (barcodeSku === activeLineOrder.lineItem.sku) {
          this.props.handleOrderUpdateLineItem({
            id: activeLineOrder.lineItem.id,
            prePrepQuantity:
              activeLineOrder.lineItem.prePrepQuantity + barcodeQuantity,
            isInPrePrep: true,
          });
          return;
        }

        // CUSTOM a printed barcode with lineItemId
      } else if (barcode[0] === "3") {
        // Check if the id matches that of the current product
        const barcodeId = barcode.slice(1, 7);
        const barcodeByIdQuantity = parseInt(barcode.slice(7, 12), 10);

        if (parseInt(barcodeId, 10) === activeLineOrder.lineItem.id) {
          this.props.handleOrderUpdateLineItem({
            id: activeLineOrder.lineItem.id,
            prePrepQuantity:
              activeLineOrder.lineItem.quantityActual + barcodeByIdQuantity,
            isInPrePrep: true,
          });
          return;
        }
      }
    }
    // Related to closing order
    //
    if (barcode === colorBarcodes.pink) {
      this.props.history.push("/pre-preparation");
      return; // exits
    }

    // REFRESH Triggers a queue transition request to refresh the page
    if (barcode === colorBarcodes.blue) {
      this.handleRefreshPage();
      return;
    }

    // LOGOUT Logs out the current user
    if (barcode === colorBarcodes.black) {
      // Programatically redirect to the logou page
      this.props.history.push("/logout?to=pre-preparation");
      return;
    }
  }

  render() {
    return (
      <div>
        <CompletionModal
          isOpened={this.state.completionModalIsOpen}
          orderNumber={this.state.orderNumber}
          username={this.props.employeeUsername}
          hasIssues={this.state.hasIssues}
          handleSubmitOrder={() =>
            this.props.handleOrderUpdateLineItem({
              id: this.state.lineItemId,
              isPrePrepComplete: true,
              isInPrePrep: false,
            })
          }
          handlePrintOrderLabel={this.props.handlePrintOrderLabel}
          closeModal={() =>
            this.setState.bind(this)({ completionModalIsOpen: false })
          }
        />
        <ProductHeaderInfo product={this.props.product} />
        {this.props.orders.map((order, index) => {
          if (index || order.lineItem.isPrePrepComplete) {
            return (
              <LineOrder
                order={order}
                key={index}
                onUndo={() =>
                  this.props.handleOrderUpdateLineItem({
                    id: order.lineItem.id,
                    isPrePrepComplete: false,
                    prePrepQuantity: 0,
                    isInPrePrep: false,
                  })
                }
              />
            );
          } else {
            return (
              <ActiveLineOrder
                order={order}
                key={index}
                currentWeight={this.props.currentWeight}
              />
            );
          }
        })}
        <Footer
          handleSubmit={this.processBarcode.bind(this)}
          counterStartTime={""}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orders: selectors.currentOrdersByProductSelector(
      state,
      ownProps.match.params.productSlug
    ),
    product: selectors.currentProductSelector(
      state,
      ownProps.match.params.productSlug
    ),
    hasOrdersToPrepare: selectors.currentProductHasOrdersToPrepare(
      state,
      ownProps.match.params.productSlug
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleGetPrePreparationOrdersByProduct: (productId) => {
      dispatch(actions.getPrePreparationOrdersByProduct(productId));
    },
    handleOrderUpdateLineItem: (lineItem) => {
      dispatch(
        actions.editLineItemPrePrep(lineItem, ownProps.match.params.productSlug)
      );
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default printerConnector(
  weightConnector(connector(PrePreparationProductPage))
);
