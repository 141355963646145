// @flow
import React, { useState } from 'react';
// Components
import Modal from 'react-modal';
import colorBarcodes from '../constants/colorBarcodes';
import BarcodeInput from './BarcodeInput';
// Styles
import './LossesModal.css';

type Losses = {
  lossesWeightFromPreparation: string | number,
  extrasWeightFromPreparation: string | number,
};

type Props = {
  isOpened: boolean,
  handleCloseModal?: () => void,
  weight: string,
  handleSubmit: (losses: Losses) => void,
};

const LossesModal = ({
  isOpened,
  handleCloseModal,
  weight,
  handleSubmit,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [lossesWeightFromPreparation, setLossesWeightFromPreparation] =
    useState(0);

  const onSubmit = (barcode) => {
    if (colorBarcodes.red === barcode && currentStep === 1) {
      return setCurrentStep(0);
    }
    if (colorBarcodes.red === barcode) {
      handleCloseModal();
    }
    if (colorBarcodes.green === barcode && currentStep === 0) {
      setLossesWeightFromPreparation(weight);
      setCurrentStep(1);
      return;
    }
    if (colorBarcodes.green === barcode && currentStep === 1) {
      handleSubmit({
        lossesWeightFromPreparation,
        extrasWeightFromPreparation: weight,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpened}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error Losses Modal"
      onRequestClose={handleCloseModal}
      className="losses-modal"
      ariaHideApp={false}
    >
      <div className="losses-modal-main">
        <div className="losses-modal-actions">
          <div className="losses-modal-action">
            <div className="active-line-item-red-block" />
            <p>Retornar</p>
          </div>
          <div className="losses-modal-action">
            <div className="active-line-item-green" />
            <p>{currentStep === 0 ? 'Aprovar' : 'Concluir'}</p>
          </div>
        </div>

        <h3 className="losses-modal-title">
          {currentStep === 0 ? 'Pesagem do sebo' : 'Pesagem das pontas'}
        </h3>

        <input
          disabled
          placeholder={currentStep === 0 ? 'Peso do sebo' : 'Peso das pontas'}
          type="text"
          name={
            currentStep === 0
              ? 'lossesWeightFromPreparation'
              : 'extrasWeightFromPreparation'
          }
          id={
            currentStep === 0
              ? 'lossesWeightFromPreparation'
              : 'extrasWeightFromPreparation'
          }
          value={weight}
          className="losses-modal-input"
        />

        <div className="losses-modal-container">
          <BarcodeInput handleSubmit={onSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default LossesModal;
