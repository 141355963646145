// @flow
import React from "react";
import { connect } from "react-redux";
// Composers
import { distributionCenterConnector } from '../../configurationModule';
// Components
import Footer from "../../components/Footer";
import MixItem from "../components/MixItem";
import PeriodicGetter from "../../components/PeriodicGetter";
import { Redirect } from "react-router-dom";
// Actions, Selectors
import * as actions from "../actions";
import * as selectors from "../selectors";
import { employeeConnector } from "../../employee";
// Styles
import "./BlendPrePreparationIndexPage.css";

const BlendPrePreparationIndexPage = (
  props
) => {
  // Upon beeping a number (Ex: 3) we navigate to the page corresponding to the mix in that position.
  const handleSubmit = (barcode: string) => {
    // If the barcode is a valid number
    if (!isNaN(barcode[0])) {
      const mixPosition = parseInt(barcode[0]);
      const mixToPrepare = props.productionMixes[mixPosition - 1];

      if (mixToPrepare) {
        props.history.push(`/blend-pre-preparation/${mixToPrepare.id}`);

        return;
      }
    }
  }
  // If the user is not logged in we should show the login page
  if (!props.employeeLoggedIn) {
    return <Redirect to="/login?to=blend-pre-preparation" />;
  }
  return (
    <div>
      {props.productionMixes.map((mix, index) => {
        return (
          <div key={index}>
            <hr className="blend-pre-preparation-index-page-line" />
            <MixItem mix={mix} position={index + 1} />
          </div>
        );
      })}
      <PeriodicGetter
        loading={props.isGettingProductionMixes}
        handleGet={props.handleGetProductionMixes}
      />
      <Footer handleSubmit={handleSubmit} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    productionMixes: selectors.productionMixesSelector(state),
    isGettingProductionMixes: selectors.gettingMixesLoadingSelector(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleGetProductionMixes: () => {
      dispatch(actions.getProductionMixes(ownProps.distributionCenter));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default distributionCenterConnector(connector(employeeConnector(BlendPrePreparationIndexPage)));
