// @flow
import { Component } from "react";
import { connect } from "react-redux";
// Actions
import { getWeight } from "../actions";
// Types

// Styles

const updateInterval = process.env.NODE_ENV === "production" ? 200 : 2000;

type Props = {
  handleGetWeight: () => void
};

class WeightGetter extends Component {
  props: Props;
  timer: number;

  componentDidMount() {
    this.timer = setInterval(
      this.props.handleGetWeight.bind(this),
      updateInterval
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleGetWeight: () => {
      dispatch(getWeight());
    }
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default connector(WeightGetter);
