// @flow
import React, { PureComponent } from "react";
import "./TimerCountdown.css";

import moment from "moment";
import "moment/locale/pt-br";

import _ from "lodash";

type Props = {
  countStartValue: number
};

type State = {
  currentCount: number
};

class TimeCounter extends PureComponent {
  state: State;
  timer: number;
  prependZero: (value: string) => string;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentCount: props.countStartValue
    };
  }

  componentDidMount() {
    this.timer = setInterval(
      () => this.setState({ currentCount: this.state.currentCount - 1 }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const hours = Math.trunc(this.state.currentCount / 3600);
    const minutes = Math.abs(
      Math.trunc(this.state.currentCount / 60) - hours * 60
    );
    const seconds = Math.abs(this.state.currentCount % 60);
    return (
      <div style={{ color: this.state.currentCount > 0 ? "green" : "red" }}>
        {" "}
        {_.padStart(hours, 2, 0) +
          ":" +
          _.padStart(minutes, 2, 0) +
          ":" +
          _.padStart(seconds, 2, 0)}
      </div>
    );
  }
}

TimeCounter.defaultProps = {
  countStartValue: 0
};

export default TimeCounter;
