// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Selectors

// Actions
import { printProductLabel, printOrderLabel } from "../actions";
// Types
import type { Dispatch } from "../../common";

type OwnProps = {
  handlePrintOrderLabel: ({ number: string, numberOfBoxes: number }) => void,
  handlePrintProductLabel: ({
    id: number,
    name: string,
    sku: string,
    quantity: number,
    unit: string
  }) => void
};

function printerConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {};
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      handlePrintOrderLabel: orderLabelParams => {
        dispatch(printOrderLabel(orderLabelParams));
      },
      handlePrintProductLabel: productLabelParams => {
        dispatch(printProductLabel(productLabelParams));
      }
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class printerConnector extends Component {
      props: OwnProps;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            handlePrintOrderLabel={this.props.handlePrintOrderLabel}
            handlePrintProductLabel={this.props.handlePrintProductLabel}
          />
        );
      }
    }
  );
}

export default printerConnector;
