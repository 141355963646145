// @flow

// Methods
import { setDistributionCenter, getDistributionCenter } from "./session";

const Api = {
  setDistributionCenter,
  getDistributionCenter
};

export default Api;
