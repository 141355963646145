// @flow
import React from "react";
// Components
import { Link } from "react-router-dom";
// Helpers
import { quantityConverter } from "../../common";
// Styles
import "./MixItem.css";

const MixItem = (props) => {
  const mix = props.mix;

  return (
    <div className="mix-item-main">
      <Link to={`/blend-pre-preparation/${mix.id}`}>
        <div className="mix-item-main-position">
          {props.position}
        </div>
        <div className="mix-item-main-image">
          <img src={`${mix.imageUrl}`} alt="" />
        </div>
        <div className="mix-item-main-name">
          <p>{mix.name}</p>
        </div>
        <div className="mix-item-main-number-of-orders">
          <p>#{mix.orderNumber}</p>
        </div>
        <div className="mix-item-main-number-of-orders">
          <p>{`Posição ${mix.preparationQueuePosition}`}</p>
        </div>
        <div className="mix-item-main-number-of-orders">
          <p>{`${mix.lineItems.length} items`}</p>
        </div>
        <div className="mix-item-data-and-info-content">
          <p>
            {quantityConverter(mix.quantity, 'g')}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default MixItem;
