import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

import * as Sentry from "@sentry/react";

export default function configureStore(preloadedState) {
  // Create middleware that is always used
  let middleware = [thunk];

  // Add middleware that should only be available during development
  if (process.env.NODE_ENV !== "production") {
    const createLogger = require("redux-logger").createLogger;
    const logger = createLogger();
    middleware = [...middleware, logger];
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  // Create store
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(...middleware), sentryReduxEnhancer),
  );

  return store;
}
