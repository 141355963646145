// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Components
import { Link } from "react-router-dom";
// Actions

// Selectors
import "../reducers";
// Styles
import "./HomePage.css";

type Props = {};

class HomePage extends Component {
  props: Props;

  render() {
    return (
      <div className="home-page-main">
        <Link to="/blend-pre-preparation">Blend Pre Preparation</Link>
        <Link to="/pre-preparation">Pre Preparation</Link>
        <Link to="/preparation">Preparation</Link>
        <Link to="/packaging">Packaging</Link>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(HomePage);
