import axios from 'axios';
import { Buffer } from 'buffer';

export const imageUpload = async (signed_url: string, photo: string) => {
  try {
    const splittedUrl = signed_url.split('Signature');
    const url = decodeURI(splittedUrl[0]) + 'Signature' + splittedUrl[1];

    const buffer = Buffer.from(
      photo.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    );

    const result = await axios.put(url, buffer, {
      headers: {
        'Content-Type': 'image/jpeg',
      },
    });
  } catch (e) {
    return { status: 'error' };
  }
};
