// @flow
import React, { Component } from "react";
//Types
import Product from "../types";
// Styles
import "./ProductHeaderInfo.css";

const ProductHeaderInfo = (props) => {
  const product = props.product;
  return (
    (product && (
      <div className="product-header-info-main-content">
        <img src={`${product.imageUrl}`} alt="" />
        <div className="product-header-info-product-content">
          <div className="product-header-info-product-content-title">
            <p>{product.name}</p>
            {product.starred && <i className="fa fa-star" aria-hidden="true" />}
          </div>
          <div className="product-header-info-product-content-subtitle">
            <span>Fabricante:</span>
            <p>{product.manufacturer}</p>
            <span>SKU:</span>
            <p>{product.sku}</p>
          </div>
          <div className="product-header-info-product-content-info-on-square-main">
            <div>
              <p>{`${product.totalQuantity} ${product.unitForQuantity}`}</p>
              <span>Total</span>
            </div>
            <div>
              <p>{`${product.totalPrePrepQuantity} ${product.unitForQuantity}`}</p>
              <span>Pré preparado</span>
            </div>
            <div>
              <p>{product.totalOrders}</p>
              <span>Total de Pedidos</span>
            </div>
          </div>
        </div>
      </div>
    )) ||
    null
  );
};

export default ProductHeaderInfo;
