// @flow
import React, { Component } from 'react';
import './PrintSaleReceipt.css';

type Props = {
  shouldPrintReceipt: boolean,
};

class PrintSaleReceiptIcon extends Component {
  props: Props;

  render() {
    return this.props.shouldPrintReceipt ? (
      <div className="print-sale-receipt">
        <i
          className="fa fa-print"
          title="A nota fiscal do cliente deve ser impressa."
        />
      </div>
    ) : null;
  }
}

export default PrintSaleReceiptIcon;
