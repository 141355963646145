// @flow
import {} from "../../common/api";

/**
 * SET DISTRIBUTION CENTER
 * Sets the distribution center into persistent storage
 */
export async function setDistributionCenter(distributionCenter: string) {
  try {
    localStorage.setItem("distribution-center", distributionCenter);
    return distributionCenter;
  } catch (err) {
    console.error(err);
  }
}

/**
 * GET DISTRIBUTION CENTER
 * Gets the distribution center from persistent storage
 */
export async function getDistributionCenter() {
  try {
    return localStorage.getItem("distribution-center");
  } catch (err) {
    console.error(err);
  }
}
