// @flow
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { employeeConnector } from '../employee';
import { weightConnector } from '../weight';
import { printerConnector } from '../printer';
import { distributionCenterConnector } from '../configurationModule';
// Components
import { Redirect } from 'react-router-dom';
import ActiveLineItem from '../components/ActiveLineItem';
import LineItem from '../components/LineItem';
import Footer from '../components/Footer';
import ErrorModal from '../components/ErrorModal';
import TotalPriceLimited from '../components/TotalPriceLimited';
import CompletionModal from '../components/CompletionModal';
import PeriodicGetter from '../components/PeriodicGetter';
import colorBarcodes from '../constants/colorBarcodes';
import OptionsComponent from '../components/OptionsComponent';
import Toast from '../components/Toast';
import LossesModal from '../components/LossesModal';
// Selectors
import {
  hasOrderSelector,
  getCurrentOrderNumberSelector,
  getCurrentOrderLineItemsSelector,
  gettingOrderLoadingSelector,
  currentOrderHasIssuesSelector,
  currentOrderHasItemsToPrepare,
  issuesByLineItemIdSelector,
  updatingAnyLineItemLoadingSelector,
  getCurrentOrderObservationSelector,
  currentOrderSelector,
} from '../selectors/orders';
// Actions
import {
  editLineItem,
  startPreparation,
  finishPreparation,
} from '../actions/orders';
import { uploadProductImage } from '../actions/lineItem';
// Types
import type { LineItem as LineItemType, Mix, Order } from '../types';
// Styles
import './PreparationPage.css';
// Helpers
import { quantityConverter } from '../common';

type Props = {
  orderId: ?number,
  orderNumber: string,
  orderLineItems: LineItemType[],
  isGettingOrder: boolean,
  hasIssues: boolean,
  orderObservation: string,
  hasItemsToPrepare: boolean,
  mix: Mix,
  handleOrderUpdateLineItem: (lineItemInformation: any) => void,
  handleQueueTransition: () => void,
  handleUploadProductImage: (image: string, activeLineItemId: string) => void,
  // From employee connector
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeHasRole: (role: string) => boolean,
  // From weight connector
  currentWeight: number,
  // From Printer Connector
  handlePrintOrderLabel: ({ number: string, numberOfBoxes: number }) => void,
  handlePrintProductLabel: ({
    id: number,
    name: string,
    sku: string,
    quantity: number,
    unit: string,
  }) => void,
  currentOrder: Order,
};

type State = {
  errorSelectionModalIsOpen: boolean,
  completionModalIsOpen: boolean,
  lineItemId: ?number,
};

const PORTIONING_SIZE_PERCENT_EXCEED_THRESHOLD = 0.1;

const PreparationPage = (props: Props) => {
  const [errorSelectionModalIsOpen, setErrorSelectionModalIsOpen] =
    useState(false);
  const [completionModalIsOpen, setCompletionModalIsOpen] = useState(false);
  const [limitedWeightModalIsOpen, setLimitedWeightModalIsOpen] =
    useState(false);
  const [lineItemId, setLineItemId] = useState(null);
  const [showPortioningSizeExceededToast, setShowPortioningSizeExceededToast] =
    useState(false);
  const [showWaitForRequestToast, setShowWaitForRequestToast] = useState(false);
  const [weightOfLastMeasurement, setWeightOfLastMeasurement] = useState(
    props.currentWeight,
  );
  const [showLossesModal, setShowLossesModal] = useState(false);
  const activeLineItem =
    props.orderLineItems && props.orderLineItems.length > 0
      ? props.orderLineItems[0]
      : null;

  useEffect(() => {
    if (!activeLineItem) {
      return;
    }
    const weightRelativeDifference = activeLineItem.portioningSize
      ? weightOfLastMeasurement / activeLineItem.portioningSize - 1
      : 0;
    setShowPortioningSizeExceededToast(
      weightRelativeDifference >= PORTIONING_SIZE_PERCENT_EXCEED_THRESHOLD,
    );
  }, [weightOfLastMeasurement]);

  const addLineItemError = (id: number) => {
    setErrorSelectionModalIsOpen(true);
    setLineItemId(id);
  };

  // Erases the associated preparation quantities for the given line item
  // This is used by the lineItem::handleClear

  const clearLineItem = (id: number) => {
    const confirmation = window.confirm(
      'Tem certeza que quer resetar esse produto?',
    );
    if (confirmation) {
      props.handleOrderUpdateLineItem({
        id: id,
        quantityActual: 0,
        packageCount: 0,
        is_processed: false, // Mark it as not processed
        putOnHold: false,
      });
    }
  };

  const capture = (activeLineItem: LineItemType) => {
    if (activeLineItem) {
      props.handleUploadProductImage(activeLineItem.id);
    }
  };

  const processBarcode = (barcode: string) => {
    if (props.hasItemsToPrepare) {
      // Any action other than adding to lineItem quantity should reset the portion size error message.
      if (barcode !== colorBarcodes.yellow) {
        setShowPortioningSizeExceededToast(false);
      }
      setShowWaitForRequestToast(false);
      // ERASE lineItem quantity, etc
      if (barcode === colorBarcodes.red) {
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          quantityActual: 0,
          packageCount: 0,
        });
        return;
        // ADD to lineItem quantity, etc
        // Adds the current weight on the scale and increments the packge count
      } else if (barcode === colorBarcodes.yellow) {
        setWeightOfLastMeasurement(props.currentWeight);
        capture(activeLineItem);
        const newWeight =
          activeLineItem.quantityActual +
          (activeLineItem.quantityUnit === 'g' ? props.currentWeight : 1);
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          quantityActual: newWeight, // Adds scale weight only if it is a weighable product
          packageCount: activeLineItem.packageCount + 1,
        });
        props.handlePrintProductLabel({
          id: activeLineItem.id,
          name: activeLineItem.name,
          sku: activeLineItem.sku,
          quantity:
            activeLineItem.quantityUnit === 'g' ? props.currentWeight : 1,
          unit: activeLineItem.quantityUnit,
        }); // Send command to print
        return;
        // DONE mark this line item as complete so we can move on to the next one
      } else if (barcode === colorBarcodes.green) {
        return setShowLossesModal(true);
        // Move line item down on the list
      } else if (barcode === colorBarcodes.cyan) {
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          putOnHold: true,
        });

        return;
        // TODO: Will be used in the future
      } else if (barcode === colorBarcodes.purple) {
        return;

        // CUSTOM a preprinted product barcode has been scanned
      } else if (barcode[0] === '2') {
        // Barcode from weighing scale CONTROL CODE 2
        // Check if the sku matches that of the current product
        const barcodeSku = barcode.slice(1, 7);
        const barcodeQuantity = parseInt(barcode.slice(7, 12), 10);

        if (barcodeSku === activeLineItem.sku) {
          props.handleOrderUpdateLineItem({
            id: activeLineItem.id,
            quantityActual: activeLineItem.quantityActual + barcodeQuantity,
            packageCount: activeLineItem.packageCount + 1,
          });
          return;
        }

        // CUSTOM a printed barcode with lineItemId
      } else if (barcode[0] === '3' || barcode[0] === '4') {
        // Check if the id matches that of the current product
        let barcodeId;
        let barcodeByIdQuantity;

        if (barcode[0] === '3') {
          barcodeId = barcode.slice(1, 7);
          barcodeByIdQuantity = parseInt(barcode.slice(7, 12), 10);
        } else {
          barcodeId = barcode.slice(1, 8);
          barcodeByIdQuantity = parseInt(barcode.slice(8, 13), 10);
        }
        if (parseInt(barcodeId, 10) === activeLineItem.id) {
          props.handleOrderUpdateLineItem({
            id: activeLineItem.id,
            quantityActual: activeLineItem.quantityActual + barcodeByIdQuantity,
            packageCount: activeLineItem.packageCount + 1,
          });
          return;
        }
      }
    }

    // If the barcode matches the user ID
    // Related to closing order
    if (barcode === colorBarcodes.pink) {
      if (props.isGettingOrder) {
        setShowWaitForRequestToast(true);
        return;
      }
      if (props.currentOrder.weightLimited) {
        const totalProducedOrderPrice = _.reduce(
          props.orderLineItems,
          (sum, lineItem) =>
            sum + Number(lineItem.quantityActual) * Number(lineItem.unitPrice),
          0,
        );
        const totalLimitOrderPrice = _.reduce(
          props.orderLineItems,
          (sum, lineItem) =>
            sum + Number(lineItem.quantityOrdered) * Number(lineItem.unitPrice),
          0,
        );
        if (totalProducedOrderPrice <= totalLimitOrderPrice) {
          setCompletionModalIsOpen(true);
        } else {
          setLimitedWeightModalIsOpen(true);
        }
      } else {
        setCompletionModalIsOpen(true);
      }

      return; // exits
    }

    // REFRESH Triggers a queue transition request to refresh the page
    if (barcode === colorBarcodes.blue) {
      props.handleQueueTransition();
      return;
    }

    // LOGOUT Logs out the current user
    if (barcode === colorBarcodes.black) {
      // Programatically redirect to the logou page
      props.history.push('/logout?to=preparation');
      return;
    }
  };

  // If the user is not logged in we should show the login page
  if (!props.employeeLoggedIn) {
    return <Redirect to="/login?to=preparation" />;
  }

  // If we don't have a order in the reducer we should keep trying to get one
  if (!props.hasOrder) {
    return (
      <div>
        <PeriodicGetter
          loading={props.isGettingOrder}
          handleGet={props.handleQueueTransition}
        />
        <Footer handleSubmit={processBarcode} />
      </div>
    );
  }

  const estimatedPreparationTimeRemaining =
    props.currentOrder.estimatedPreparationTime * 60 -
    (props.currentOrder.timeInStates &&
      props.currentOrder.timeInStates.preparation);
  return (
    <div className="">
      <TotalPriceLimited
        isOpened={limitedWeightModalIsOpen}
        closeModal={() => setLimitedWeightModalIsOpen(false)}
      />
      {showPortioningSizeExceededToast && (
        <Toast
          position="bottom-left"
          title="Possivel erro"
          description={`O cliente pediu o produto porcionado em pacotes de
        ${quantityConverter(activeLineItem.portioningSize, 'g')}.
        Você acabou de pesar uma porção de
        ${quantityConverter(weightOfLastMeasurement, 'g')}. Tem
        certeza que está correto?`}
          backgroundColor="#8B0000"
          onClose={() => setShowPortioningSizeExceededToast(false)}
        />
      )}
      {showWaitForRequestToast && (
        <Toast
          position="bottom-left"
          title="Código de barras ignorado."
          description={`A requisição anterior ainda não foi processada por completo, fazer outra nesse estado é uma potencial fonte de erros. Tente novamente dentro de alguns instantes.`}
          backgroundColor="#8B0000"
          onClose={() => setShowWaitForRequestToast(false)}
        />
      )}
      {showLossesModal && (
        <LossesModal
          isOpened={showLossesModal}
          handleCloseModal={() => setShowLossesModal(false)}
          handleSubmit={(value) => {
            setShowLossesModal(false);
            props.handleOrderUpdateLineItem({
              id: activeLineItem.id,
              isProcessed: true,
              lossesWeightFromPreparation: value.lossesWeightFromPreparation,
              extrasWeightFromPreparation: value.extrasWeightFromPreparation,
            });
          }}
          weight={props.currentWeight}
        />
      )}

      <ErrorModal
        lineItemId={lineItemId}
        isOpened={errorSelectionModalIsOpen}
        closeModal={() => setErrorSelectionModalIsOpen(false)}
      />
      <CompletionModal
        isOpened={completionModalIsOpen}
        orderNumber={props.orderNumber}
        username={props.employeeUsername}
        hasIssues={props.hasIssues}
        handleSubmitOrder={props.handleEditOrderPreparation}
        handlePrintOrderLabel={props.handlePrintOrderLabel}
        closeModal={() => setCompletionModalIsOpen(false)}
      />
      <div>
        <OptionsComponent
          weightLimited={props.currentOrder.weightLimited}
          mixes={props.currentOrder.mixes}
          orderObservation={props.orderObservation}
        />
        {props.orderLineItems.map((l, ind) => {
          const mix = _.filter(props.currentOrder.mixes, function (o) {
            return o.id === l.mixId;
          }).pop();
          const mixIndex = _.findIndex(props.currentOrder.mixes, mix);

          if (ind || l.isProcessed) {
            // If we are not the first component or.bind(this) the line item has been processed
            return (
              <LineItem
                key={ind}
                canEditError={false}
                issues={props.issuesByLineItem(l.id)}
                isComplete={l.isProcessed} // TODO
                imageUrl={l.imageUrl}
                sku={l.sku}
                name={l.name}
                optionValues={
                  l.options ? l.options.map((o) => (o ? o.value : '')) : []
                }
                quantityOrdered={l.quantityOrdered}
                putOnHoldAt={l.putOnHoldAt}
                quantityActual={l.quantityActual}
                prePrepQuantity={l.prePrepQuantity}
                quantityRevised={null}
                quantityUnit={l.quantityUnit}
                portioningSize={l.portioningSize}
                preparationImages={l.preparationImages}
                handleAddError={() => addLineItemError(l.id)}
                handleClear={() => clearLineItem(l.id)}
                mix={mix}
                mixIndex={mixIndex}
                observation={l.observation}
                enableReset={true}
              />
            );
          } else {
            return (
              <ActiveLineItem
                key={ind}
                canEditError={false}
                issues={props.issuesByLineItem(l.id)}
                imageUrl={l.imageUrl}
                sku={l.sku}
                name={l.name}
                optionValues={
                  l.options ? l.options.map((o) => (o ? o.value : '')) : []
                }
                quantityOrdered={l.quantityOrdered}
                putOnHoldAt={l.putOnHoldAt}
                quantityActual={l.quantityActual}
                prePrepQuantity={l.prePrepQuantity}
                quantityUnit={l.quantityUnit}
                packageCount={l.packageCount}
                currentWeight={props.currentWeight}
                handleAddError={() => addLineItemError(l.id)}
                lineItems={props.orderLineItems}
                unitPrice={l.unitPrice}
                weightLimited={props.currentOrder.weightLimited}
                portioningSize={l.portioningSize}
                handleClear={() => clearLineItem(l.id)}
                mix={mix}
                mixIndex={mixIndex}
                observation={l.observation}
              />
            );
          }
        })}
      </div>
      <Footer
        handleSubmit={processBarcode}
        counterStartTime={estimatedPreparationTimeRemaining}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps: {}) => {
  return {
    hasOrder: hasOrderSelector(state),
    orderNumber: getCurrentOrderNumberSelector(state),
    orderLineItems: getCurrentOrderLineItemsSelector(state),
    // TODO: Decouple these loading states so they can be shown differently.
    isGettingOrder:
      gettingOrderLoadingSelector(state) ||
      updatingAnyLineItemLoadingSelector(state),
    hasIssues: currentOrderHasIssuesSelector(state),
    hasItemsToPrepare: currentOrderHasItemsToPrepare(state),
    issuesByLineItem: (itemId: number) =>
      issuesByLineItemIdSelector(state, itemId),
    orderObservation: getCurrentOrderObservationSelector(state),
    currentOrder: currentOrderSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleOrderUpdateLineItem: (lineItem) => {
      dispatch(editLineItem(lineItem));
    },
    handleQueueTransition: () => {
      // We should only transition orders that belong to our distributionCenter
      dispatch(startPreparation(ownProps.distributionCenter));
    },
    handleEditOrderPreparation: (orderNumber: string, boxesCount: number) => {
      dispatch(finishPreparation(orderNumber, boxesCount));
    },
    handleUploadProductImage: (activeLineItemId: string) => {
      dispatch(uploadProductImage(activeLineItemId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default distributionCenterConnector(
  printerConnector(
    weightConnector(employeeConnector(connector(PreparationPage))),
  ),
);
