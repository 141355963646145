// @flow
// Helpers
import { authGet, authPut, authPost } from "../../common/api";
import humps from "humps";
import _ from "lodash";
import { normalize } from "normalizr";
// Schema
import * as schema from "./schema";
import { updateProductionMix } from "../actions";

const api = {
  async getProductionMixes(distributionCenter: string) {
    const response = await authGet(`/mixes?by_distribution_center=${distributionCenter}`);
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.mixes);
    return normalized;
  },
  async getProductionMix(mixId: string) {
    const response = await authGet(`/mixes/${mixId}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.mix);
    return normalized;
  },
  async startBlendPrePreparation(distributionCenter: string) {
    const response = await authPost(`/mixes/pre_preparation?by_distribution_center=${distributionCenter}`);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.mix);
    return normalized;
  },
  async updateProductionMix(mixId: string, params) {
    let body = _.omitBy(params, v => v === null || v === "");
    body = humps.decamelizeKeys(body);
    const response = await authPut(`/mixes/${mixId}`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.mix);
    return normalized;
  },
  async addBlendPrePreparedQuantity(mixId: string, quantity: number) {
    const body = { quantity: quantity };
    const response = await authPut(`/mixes/${mixId}/add_pre_prepared_quantity`, body);
    const data = await response.json().then(b => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.mix);
    return normalized;
  },
};

export default api;
