import React, { Component } from "react";
import routes from "./routes";
// Components
import { Validate } from './employee';
import { Router, Switch, Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Header from './components/Header';

import * as Sentry from '@sentry/react';

const history = createHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "https://0a9a404e5341461b9be1a7d35b4f8d0c@sentry.io/185925",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  release: process.env.REACT_APP_BUILD_COMMIT,
});

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div>
          <Validate />
          <Switch>
            <Route path="/login" exact={true} component={null} />
            <Route path="/" component={Header} />
          </Switch>
          <Switch>
            {routes.map((route, ind) => (
              <Route
                key={ind}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
