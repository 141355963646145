// @flow
import React from 'react';
// Components
import Modal from 'react-modal';
import BarcodeInput from './BarcodeInput';
// Constants
import colorBarcodes from '../constants/colorBarcodes';
// Styles
import './QuantityAlertModal.css';
// Types
import { LineItem as LineItemType } from '../types';

type Props = {
  isOpen: boolean,
  handleSubmit: () => void,
  handleCloseModal: () => void,
  lineItems: LineItemType[],
};

const QuantityAlertModal = ({
  isOpen,
  handleSubmit,
  handleCloseModal,
  lineItems = [],
}: Props) => {
  const onSubmit = (barcode: string) => {
    if (colorBarcodes.red === barcode) return handleCloseModal();
    if (colorBarcodes.green === barcode) return handleSubmit();
    return handleCloseModal();
  };

  const filteredLineItems = lineItems.filter((li) => li.quantityActual <= 0);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      contentLabel="Há items que não foram pesados corretamente"
      onRequestClose={handleCloseModal}
      className="quantity-alert-modal"
      ariaHideApp={false}
    >
      <div className="quantity-alert-modal-main">
        <section className="quantity-alert-modal-actions">
          <div className="quantity-alert-modal-action">
            <div className="active-line-item-red-block" />
            <p>Retornar</p>
          </div>
          <div className="quantity-alert-modal-action">
            <div className="active-line-item-green" />
            <p>Prosseguir assim mesmo</p>
          </div>
        </section>

        <h3 className="quantity-alert-modal-title">
          Atenção, os items abaixo ainda não foram pesados
        </h3>
        <ul className="quantity-alert-item-wrapper">
          {filteredLineItems.map((lineItem, index) => (
            <li key={index}>
              <img src={lineItem.imageUrl} alt={`Item ${index + 1}`} />
              {lineItem.name}
            </li>
          ))}
        </ul>
        <div className="quantity-alert-modal-container">
          <BarcodeInput handleSubmit={onSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default QuantityAlertModal;
