const weightServerAddress = "http://localhost:8010";

const Printer = {
  async printOrderLabel(body) {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const result = await fetch(`${weightServerAddress}/print/order`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    });

    if (!result.ok) {
      throw new Error(result.statusText);
    }

    const response = await result.json();

    return response;
  },
  async printProductLabel(body) {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const result = await fetch(`${weightServerAddress}/print/product`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    });

    if (!result.ok) {
      throw new Error(result.statusText);
    }

    const response = await result.json();

    return response;
  }
};

export default Printer;
