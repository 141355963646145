// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Components
import ProductItem from "../components/ProductItem";
import PeriodicGetter from "../../components/PeriodicGetter";
import { Redirect } from "react-router-dom";
// Actions, Selectors
import * as actions from "../actions";
import * as selectors from "../selectors";
import { employeeConnector } from "../../employee";
// Styles
import "./PrePreparationIndexPage.css";

type Props = {
  employeeLoggedIn: boolean,
};

class PrePreparationIndexPage extends Component {
  render() {
    // If the user is not logged in we should show the login page
    if (!this.props.employeeLoggedIn) {
      return <Redirect to="/login?to=pre-preparation" />;
    }
    return (
      <div>
        {this.props.products.map((product, index) => {
          return (
            <div key={index}>
              <hr className="pre-preparation-index-page-line" />
              <ProductItem product={product} key={index} />
            </div>
          );
        })}
        <PeriodicGetter
          loading={this.props.isGettingProducts}
          handleGet={this.props.handleGetPrePreparationIndex}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    products: selectors.currentPrePreparationsSelector(state),
    isGettingProducts: selectors.gettingProductsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleGetPrePreparationIndex: () => {
      dispatch(actions.getPrePreparationProductIndex());
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(employeeConnector(PrePreparationIndexPage));
