// @flow
import { createAction } from "redux-actions";
// Apis
import Api from "../api";
// Logger
import { logAPIException } from "../../logHelper";

export const fetchWeightRequest = createAction("FETCH_WEIGHT_REQUEST");
export const fetchWeight = createAction("FETCH_WEIGHT");

export function getWeight() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchWeightRequest());
    try {
      const response = await Api.getWeight();
      dispatch(fetchWeight({ weight: parseInt(response.weight, 10) }));
    } catch (err) {
      // logAPIException(err);
      dispatch(fetchWeight(err));
    }
  };
}
