// @flow
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { employeeConnector } from '../../employee';
import { weightConnector } from '../../weight';
import { printerConnector } from '../../printer';
import { distributionCenterConnector } from '../../configurationModule';
// Components
import { Redirect } from 'react-router-dom';
import { WeightGetter } from "../../weight";
import ActiveLineItem from '../components/ActiveLineItem';
import LineItem from '../components/LineItem';
import Footer from '../../components/Footer';
import ErrorModal from '../../components/ErrorModal';
import TotalPriceLimited from '../../components/TotalPriceLimited';
import BlendPrePreparationNotCompleteModal from '../../common/components/CustomModal';
import MissingMixPreWeightModal from '../../common/components/CustomModal';
import PeriodicGetter from '../../components/PeriodicGetter';
import colorBarcodes from '../../constants/colorBarcodes';
import OptionsComponent from '../../components/OptionsComponent';
// Selectors
import {
  gettingOrderLoadingSelector
} from '../../selectors/orders';
import {
  currentMixSelector,
  currentMixOrderSelector,
  currentMixLineItemsSelector,
  currentMixHasItemsToPreWeigh,
  currentMixHasItemsToPrepare,
  currentMixHasItemsWithEmptyPrePrepQuantity,
  currentMixHasIssuesSelector,
  mixLineItemsToPrintSelector,
  getMixOrderObservationSelector,
  hasMixOrderSelector,
  currentMixIssuesSelector,
} from '../selectors'
// Actions
import {
  startBlendPrePreparation,
  getProductionMix,
  updateProductionMix,
  addProductionMixPrePreparedQuantity
} from '../actions';
import {
  getOrderByNumber,
  editLineItem
} from '../../actions/orders';
import { uploadProductImage } from '../../actions/lineItem';
// Types
import type { LineItem as LineItemType, Mix } from '../../types';
// Styles
import './BlendPrePreparationMixPage.css';

type Props = {
  orderId: ?number,
  hasMixOrder: Boolean,
  isGettingOrder: boolean,
  hasIssues: boolean,
  orderObservation: string,
  currentMixHasItemsToPrepare: boolean,
  currentMix: Mix,
  handleGetMixByMixId: (mixId: string) => void,
  handleFetchOrderByNumber: (orderNumber: string) => void,
  handleOrderUpdateLineItem: (lineItemInformation: any) => void,
  handleUploadProductImage: (image: string, activeLineItemId: string) => void,
  // From employee connector
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeHasRole: (role: string) => boolean,
  // From weight connector
  currentWeight: number,
  // From Printer Connector
  handlePrintOrderLabel: ({ number: string, numberOfBoxes: number }) => void,
  handlePrintProductLabel: ({
    id: number,
    name: string,
    sku: string,
    quantity: number,
    unit: string,
  }) => void,
};

type State = {
  errorSelectionModalIsOpen: boolean,
  lineItemId: ?number,
};

const BlendPrePreparationMixPage = (props: Props) => {
  const [errorSelectionModalIsOpen, setErrorSelectionModalIsOpen] =
    useState(false);
  const [limitedWeightModalIsOpen, setLimitedWeightModalIsOpen] =
    useState(false);
  const [blendPrePreparationNotCompleteModalIsOpen, setBlendPrePreparationNotCompleteModalIsOpen] =
    useState(false);
  const [missingItemPreWeightModalIsOpen, setMissingItemPreWeightModalIsOpen] = useState(false);
  const [lineItemId, setLineItemId] = useState(null);

  const addLineItemError = (id: number) => {
    setErrorSelectionModalIsOpen(true);
    setLineItemId(id);
  };

  useEffect(() => {
    if (props.currentMix) {
      props.handleFetchOrderByNumber(props.currentMix.orderNumber);
    }
  }, [props.currentMix]);

  useEffect(() => {
    if (props.mixLineItemsToPrint) {
      _.forEach(props.mixLineItemsToPrint, (l) => {
        props.handlePrintProductLabel({
          id: l.id,
          name: l.name,
          sku: l.sku,
          quantity: l.prePrepQuantity - l.mixPrevPrePrepQuantity,
          unit: l.quantityUnit,
        });
      });
    }
  }, [props.mixLineItemsToPrint]);

  const finishBlendPrePreparation = () => {
    if (props.currentMixHasItemsToPrepare || props.currentMixHasItemsWithEmptyPrePrepQuantity) {
      setBlendPrePreparationNotCompleteModalIsOpen(true);
    } else {
      props.handlePrintOrderLabel({
        number: props.currentMix.orderNumber, 
        numberOfBoxes:1,
      });
      props.handleCompleteMixPrePreparation(props.currentMix.id);
      props.handleQueueTransition();
    }
  };


  const clearLineItem = (id: number) => {
    const confirmation = window.confirm(
      'Tem certeza que quer resetar esse produto?',
    );
    if (confirmation) {
      props.handleOrderUpdateLineItem({
        id: id,
        mixPreWeightedQuantity: 0,
        prePrepQuantity: 0,
        isPrePrepComplete: false, // Mark it as not completed
        putOnHold: false,
      });
    }
  };

  const capture = (activeLineItem: LineItemType) => {
    if (activeLineItem) {
      props.handleUploadProductImage(activeLineItem.id);
    }
  };

  const processBarcode = (barcode: string) => {
    const activeLineItem = props.currentMixLineItems[0]; // TODO: What happens where there is not active line item? Won't this break?

    if (props.currentMixHasItemsToPrepare) {
      // ERASE lineItem quantity, etc
      if (barcode === colorBarcodes.red) {
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          mixPreWeightedQuantity: 0,
          prePrepQuantity: 0,
          isPrePrepComplete: false,
          isInPrePrep: false,
          packageCount: 0
        });
        return;
        // ADD to lineItem quantity, etc
        // Adds the current weight on the scale
      } else if (barcode === colorBarcodes.yellow) {
        capture(activeLineItem);
        const newWeight =
          activeLineItem.mixPreWeightedQuantity +
          (activeLineItem.quantityUnit === 'g' ? props.currentWeight : 1);
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          mixPreWeightedQuantity: newWeight, // Adds scale weight only if it is a weighable product
          isInPrePrep: true
        });

        return;
        // DONE mark this line item as complete so we can move on to the next one
      } else if (barcode === colorBarcodes.green) {
        if (activeLineItem.mixPreWeightedQuantity == null || activeLineItem.mixPreWeightedQuantity == 0) {
          setMissingItemPreWeightModalIsOpen(true);

          return;
        }

        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          isPrePrepComplete: true,
          isInPrePrep: false
        });

        return;
        // Move line item down on the list
      } else if (barcode === colorBarcodes.cyan) {
        props.handleOrderUpdateLineItem({
          id: activeLineItem.id,
          putOnHold: true,
          isInPrePrep: false,
        });

        return;
      } else if (barcode === colorBarcodes.purple) {
        props.history.push('/blend-pre-preparation');

        return;

        // CUSTOM a preprinted product barcode has been scanned
      } else if (barcode[0] === '2') {
        // Barcode from weighing scale CONTROL CODE 2
        // Check if the sku matches that of the current product
        const barcodeSku = barcode.slice(1, 7);
        const barcodeQuantity = parseInt(barcode.slice(7, 12), 10);

        if (barcodeSku === activeLineItem.sku) {
          props.handleOrderUpdateLineItem({
            id: activeLineItem.id,
            mixPreWeightedQuantity: activeLineItem.mixPreWeightedQuantity + barcodeQuantity,
            isInPrePrep: true
          });
          return;
        }

        // CUSTOM a printed barcode with lineItemId
      } else if (barcode[0] === '3' || barcode[0] === '4') {
        // Check if the id matches that of the current product
        let barcodeId;
        let barcodeByIdQuantity;

        if (barcode[0] === '3') {
          barcodeId = barcode.slice(1, 7);
          barcodeByIdQuantity = parseInt(barcode.slice(7, 12), 10);
        } else {
          barcodeId = barcode.slice(1, 8);
          barcodeByIdQuantity = parseInt(barcode.slice(8, 13), 10);
        }
        if (parseInt(barcodeId, 10) === activeLineItem.id) {
          props.handleOrderUpdateLineItem({
            id: activeLineItem.id,
            mixPreWeightedQuantity: activeLineItem.mixPreWeightedQuantity + barcodeByIdQuantity,
            isInPrePrep: true
          });
          return;
        }
      }
    } else if (barcode === colorBarcodes.yellow) {
      if (props.currentMixHasItemsToPreWeigh) {
        setMissingItemPreWeightModalIsOpen(true);

        return;
      }

      props.handleAddMixPrePreparedQuantity(props.currentMix.id, props.currentWeight);

      return;
    }

    // If the barcode matches the user ID
    // Related to closing order
    if (barcode === colorBarcodes.pink) {
      if (props.currentMix.weightLimited) {
        const totalProducedMixPrice = _.reduce(
          props.currentMixLineItems,
          (sum, lineItem) =>
            sum + Number(lineItem.mixPreWeightedQuantity) * Number(lineItem.unitPrice),
          0,
        );
        const totalLimitMixPrice = _.reduce(
          props.currentMixLineItems,
          (sum, lineItem) =>
            sum + Number(lineItem.quantityOrdered) * Number(lineItem.unitPrice),
          0,
        );
        if (totalProducedMixPrice <= totalLimitMixPrice) {
          finishBlendPrePreparation();
        } else {
          setLimitedWeightModalIsOpen(true);
        }
      } else {
        finishBlendPrePreparation();
      }

      return; // exits
    }

    // REFRESH Triggers a queue transition request to refresh the page
    if (barcode === colorBarcodes.blue) {
      props.match.params.mixId ? props.handleGetMixByMixId(props.match.params.mixId) : props.handleQueueTransition;
      return;
    }

    // LOGOUT Logs out the current user
    if (barcode === colorBarcodes.black) {
      // Programatically redirect to the logou page
      props.history.push('/logout?to=blend-pre-preparation');
      return;
    }
  };

  // If the user is not logged in we should show the login page
  if (!props.employeeLoggedIn) {
    return <Redirect to="/login?to=blend-pre-preparation" />;
  }

  // If we don't have a order in the reducer we should keep trying to get one
  if (!props.hasMixOrder) {
    return (
      <div>
        <PeriodicGetter
          loading={props.isGettingOrder}
          handleGet={props.match.params.mixId ? () => props.handleGetMixByMixId(props.match.params.mixId) : props.handleQueueTransition}
        />
        <Footer handleSubmit={processBarcode} />
      </div>
    );
  }

  const estimatedPreparationTimeRemaining =
    props.mixOrder.estimatedPreparationTime * 60 -
    (props.mixOrder.timeInStates &&
      props.mixOrder.timeInStates.preparation);

  return (
    <div className="">
      {!props.currentMixHasItemsToPrepare ? <WeightGetter /> : ''}
      <TotalPriceLimited
        isOpened={limitedWeightModalIsOpen}
        closeModal={() => setLimitedWeightModalIsOpen(false)}
      />
      <BlendPrePreparationNotCompleteModal
        title='Esse pedido possui itens não processados'
        content='Verifique novamente se todos os itens foram pesados, e caso esteja correto, 
                 informe ao supervisor sobre essa situação.
                 Pressione qualquer código ou qualquer tecla para voltar a tela anterior.'
        isOpened={blendPrePreparationNotCompleteModalIsOpen}
        closeModal={() => setBlendPrePreparationNotCompleteModalIsOpen(false)}
      />
      <MissingMixPreWeightModal
        title='Este item não foi pré-processado'
        content='Verifique se a pré-pesagem para este item está correta, e caso esteja, 
                 informe ao supervisor sobre essa situação.
                 Pressione qualquer código ou qualquer tecla para voltar a tela anterior.'
        isOpened={missingItemPreWeightModalIsOpen}
        closeModal={() => setMissingItemPreWeightModalIsOpen(false)}
      />
      <ErrorModal
        lineItemId={lineItemId}
        isOpened={errorSelectionModalIsOpen}
        closeModal={() => setErrorSelectionModalIsOpen(false)}
      />
      <div>
        <OptionsComponent
          weightLimited={props.mixOrder.weightLimited}
          mixes={[props.currentMix]}
          orderObservation={props.orderObservation}
        />

        {!props.currentMixHasItemsToPrepare ? (
          <div className="active-line-item-data-container">
            <p className="active-line-item-large-font">
              {props.currentWeight
                ? props.currentWeight + " g"
                : "--"}
            </p>
            <p>Balança</p>
          </div>
        ) : ''}

        {props.currentMixLineItems.map((l, ind) => {
          const mix = _.filter(props.mixOrder.mixes, function (o) {
            return o.id === l.mixId;
          }).pop();
          const mixIndex = _.findIndex(props.mixOrder.mixes, mix);

          if (ind || l.isPrePrepComplete) {
            // If we are not the first component or.bind(this) the line item has been completed
            return (
              <LineItem
                key={ind}
                canEditError={false}
                issues={props.mixIssues}
                isComplete={l.isPrePrepComplete} // TODO
                imageUrl={l.imageUrl}
                sku={l.sku}
                name={l.name}
                optionValues={
                  l.options ? l.options.map((o) => (o ? o.value : '')) : []
                }
                quantityOrdered={l.quantityOrdered}
                putOnHoldAt={l.putOnHoldAt}
                mixPreWeightedQuantity={l.mixPreWeightedQuantity}
                prePrepQuantity={l.prePrepQuantity}
                isInPrePrep={l.isInPrePrep}
                quantityUnit={l.quantityUnit}
                portioningSize={l.portioningSize}
                preparationImages={l.preparationImages}
                handleAddError={() => addLineItemError(l.id)}
                handleClear={() => clearLineItem(l.id)}
                mix={mix}
                mixIndex={mixIndex}
                observation={l.observation}
                enableReset={true}
              />
            );
          } else {
            return (
              <ActiveLineItem
                key={ind}
                canEditError={false}
                issues={props.mixIssues}
                imageUrl={l.imageUrl}
                sku={l.sku}
                name={l.name}
                optionValues={
                  l.options ? l.options.map((o) => (o ? o.value : '')) : []
                }
                quantityOrdered={l.quantityOrdered}
                putOnHoldAt={l.putOnHoldAt}
                mixPreWeightedQuantity={l.mixPreWeightedQuantity}
                prePrepQuantity={l.prePrepQuantity}
                quantityUnit={l.quantityUnit}
                currentWeight={props.currentWeight}
                handleAddError={() => addLineItemError(l.id)}
                lineItems={props.currentMixLineItems}
                unitPrice={l.unitPrice}
                weightLimited={props.mixOrder.weightLimited}
                portioningSize={l.portioningSize}
                handleClear={() => clearLineItem(l.id)}
                mix={mix}
                mixIndex={mixIndex}
                observation={l.observation}
              />
            );
          }
        })}
      </div>
      <Footer
        handleSubmit={processBarcode}
        counterStartTime={estimatedPreparationTimeRemaining}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps: {}) => {
  return {
    currentMix: currentMixSelector(state),
    currentMixLineItems: currentMixLineItemsSelector(state),
    mixLineItemsToPrint: mixLineItemsToPrintSelector(state),
    mixOrder: currentMixOrderSelector(state),
    currentMixHasItemsToPreWeigh: currentMixHasItemsToPreWeigh(state),
    currentMixHasItemsToPrepare: currentMixHasItemsToPrepare(state),
    currentMixHasItemsWithEmptyPrePrepQuantity: currentMixHasItemsWithEmptyPrePrepQuantity(state),
    // TODO: Decouple these loading states so they can be shown differently.
    hasIssues: currentMixHasIssuesSelector(state),
    mixIssues: currentMixIssuesSelector(state),
    orderObservation: getMixOrderObservationSelector(state),
    isGettingOrder: gettingOrderLoadingSelector(state),
    hasMixOrder: hasMixOrderSelector(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleGetMixByMixId: (mixId) => {
      dispatch(getProductionMix(mixId));
    },
    handleFetchOrderByNumber: (orderNumber) => {
      dispatch(getOrderByNumber(orderNumber));
    },
    handleOrderUpdateLineItem: (lineItem) => {
      dispatch(editLineItem(lineItem));
    },
    handleAddMixPrePreparedQuantity: (mixId, quantity) => {
      dispatch(addProductionMixPrePreparedQuantity(mixId, quantity));
    },
    handleCompleteMixPrePreparation: (mixId) => {
      dispatch(updateProductionMix(mixId, { isPrePrepComplete: true }));
    },
    handleQueueTransition: () => {
      // We should only transition blends that belong to the current distributionCenter
      dispatch(startBlendPrePreparation(ownProps.distributionCenter));
    },
    handleUploadProductImage: (activeLineItemId: string) => {
      dispatch(uploadProductImage(activeLineItemId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default distributionCenterConnector(
  printerConnector(
    weightConnector(employeeConnector(connector(BlendPrePreparationMixPage))),
  ),
);
