import * as Sentry from '@sentry/react';

// Required for logging with sentry error that occur within try-catch blocks
export function logAPIException(
  ex: Error,
  context?,
  severity?: Sentry.SeverityLevel,
) {
  try {
    const errorData = JSON.parse(ex.message);
    if (context) {
      Sentry.setContext('additional data', context);
    }
    if (severity) {
      Sentry.captureException(ex, { level: severity });
    } else {
      Sentry.captureException(ex, {
        // considers 401 forbidden (not logged) and 422 unprocessable entity (invalid client input) to not be errors
        level: [401, 422].includes(errorData.statusCode) ? 'warning' : 'error',
      });
    }
  } catch (e) {
    logException(e);
  }
}

export function logException(
  err: Error,
  context?,
  severity?: Sentry.SeverityLevel,
) {
  if (context) {
    Sentry.setContext('additional data', context);
  }
  Sentry.captureException(err, severity ? { level: severity } : undefined);
}

export function logMessage(
  message: String,
  context?,
  severity?: Sentry.SeverityLevel,
) {
  if (context) {
    Sentry.setContext('additional data', context);
  }
  Sentry.captureMessage(message, severity ? severity : undefined);
}
