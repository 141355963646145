// @flow
import React, { Component } from "react";
import "./BarcodeInput.css";

type Props = {
  handleSubmit: (barcode: string) => void
};

class BarcodeInput extends Component {
  props: Props;

  render() {
    return (
      <div className="barcode-input-main">
        <div>Código de barras</div>
        <form
          className="barcode-form"
          onSubmit={e => {
            e.preventDefault();
            this.props.handleSubmit(this.refs.barcode.value);
            this.refs.barcode.value = "";
          }}
        >
          <input
            type="text"
            className="barcode-input"
            autoFocus
            ref="barcode"
          />
          <input type="submit" className="barcode-button" value="Submit" />
        </form>
      </div>
    );
  }
}

export default BarcodeInput;
