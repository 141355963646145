// @flow
import React, { Component } from "react";

import "./Loader.css";

class Loader extends Component {
  render() {
    return (
      <div className="loading-main">
        <div className="loading-box">
          <i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
          <div className="loading-text">Atualizando...</div>
        </div>
      </div>
    );
  }
}

export default Loader;
