// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Selectors
import { currentWeightSelector } from "../selectors";
// Actions

// Types
import type { Dispatch } from "../../common";

type OwnProps = {
  currentWeight: number
};

function weightConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      currentWeight: currentWeightSelector(state)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class weightConnector extends Component {
      props: OwnProps;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            currentWeight={this.props.currentWeight}
          />
        );
      }
    }
  );
}

export default weightConnector;
