// @flow
// Helpers
import { authGet, authPut, authPost } from "../../common/api";
import humps from "humps";
import qs from "qs";
import _ from "lodash";
import { normalize } from "normalizr";
// Schema
import * as schema from "./schema";

const api = {
  async getPrePreparationIndex() {
    const response = await authGet("/pre_prep");
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async getPrePreparationOrdersByProduct(productId: string) {
    const response = await authGet(`/pre_prep/${productId}`);
    const data = await response.json().then((b) => humps.camelizeKeys(b));

    const normalized = normalize(data, schema.product);
    return normalized;
  },
  async updateLineItem(updatableLineItem) {
    // Decamelize keys and stringify before sending
    // Remove entries that are null or blank
    let body = _.omitBy(updatableLineItem, (v) => v === null || v === "");
    body = humps.decamelizeKeys(body);

    const response = await authPut(`/line_items/${updatableLineItem.id}`, body);
    const data = await response.json().then((b) => humps.camelizeKeys(b));
    const normalized = normalize(data, schema.lineItem);
    return normalized;
  },
};

export default api;
