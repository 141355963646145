// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Selectors
import * as Selectors from "../selectors";
// Actions
import * as Actions from "../actions";
// Types
import type { Dispatch } from "../../common";

type OwnProps = {
  distributionCenter: string,
  gettingLoading: boolean,
  settingLoading: boolean
};

function distributionCenterConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      distributionCenter: Selectors.distributionCenterSelector(state),
      gettingLoading: Selectors.gettingLoadingSelector(state)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {
      handleGetDistributionCenter: () => {
        dispatch(Actions.fetchDistributionCenter());
      }
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class distributionCenterConnector extends Component {
      constructor(props: OwnProps) {
        super(props);

        // We should request that the distribution center be retrieved from localStorage
        this.props.handleGetDistributionCenter();
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            distributionCenter={this.props.distributionCenter}
            distributionCenterLoading={this.props.gettingLoading}
          />
        );
      }
    }
  );
}

export default distributionCenterConnector;
