// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
// Selectors
import {
  loggedInSelector,
  employeeUsernameSelector,
  employeeLoggingInLoadingSelector,
  employeeValidatingLoadingSelector,
  employeeRolesNameSelector,
  employeeHasRoleSelector
} from "../selectors";
// Actions

// Types
import type { Dispatch } from "../../common";

type OwnProps = {
  employeeLoggedIn: boolean,
  employeeUsername: string,
  employeeLoggingInLoading: boolean,
  employeeValidatingLoading: boolean,
  employeeRoles: string[],
  employeeHasRole: (role: string) => boolean
};

function employeeConnector(WrappedComponent: ReactClass<any>) {
  const mapStateToProps = (state, ownProps) => {
    return {
      employeeLoggedIn: loggedInSelector(state),
      employeeUsername: employeeUsernameSelector(state),
      employeeLoggingInLoading: employeeLoggingInLoadingSelector(state),
      employeeValidatingLoading: employeeValidatingLoadingSelector(state),
      employeeRoles: employeeRolesNameSelector(state),
      employeeHasRole: role => employeeHasRoleSelector(state, role)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
    return {};
  };

  return connect(mapStateToProps, mapDispatchToProps)(
    class employeeConnector extends Component {
      props: OwnProps;

      render() {
        return (
          <WrappedComponent
            {...this.props}
            employeeLoggedIn={this.props.employeeLoggedIn}
            employeeUsername={this.props.employeeUsername}
            employeeLoggingInLoading={this.props.employeeLoggingInLoading}
            employeeValidatingLoading={this.props.employeeValidatingLoading}
            employeeRoles={this.props.employeeRoles}
            employeeHasRole={this.props.employeeHasRole}
          />
        );
      }
    }
  );
}

export default employeeConnector;
