// @flow
import { combineReducers } from "redux";
import { handleActions, combineActions } from "redux-actions";
import _ from "lodash";
// Actions
import * as actions from "../actions";
import * as orderActions from "../../actions/orders";

////////////////////////////// CURRENT BLEND-PRE-PREPARATION LIST
const currentProductionMixesListInitialState = [];
const currentProductionMixesList = handleActions(
  {
    [actions.fetchProductionMixes]: {
      next: (state, action) => action.payload.result,
    },
  },
  currentProductionMixesListInitialState
);

const productionMixesInitialState = [];
const productionMixes = handleActions(
  {
    [combineActions(actions.fetchProductionMixes, actions.fetchProductionMix,
      actions.postStartBlendPrePreparation)]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.mixes })
    },
  },
  productionMixesInitialState
);

const currentMixInitialState = null;
const currentMix = handleActions(
  {
    [combineActions(actions.fetchProductionMix, actions.postStartBlendPrePreparation,
      actions.putProductionMixPrePreparedQuantity)]: {
      next: (state, action) => action.payload.entities.mixes[action.payload.result]
    },
    [actions.fetchProductionMixes]: {
      next: (state, action) => currentMixInitialState
    },
  },
  currentMixInitialState
);

const mixLineItemsToPrintInitialState = {};
const mixLineItemsToPrint = handleActions(
  {
    [actions.getProductionMixToPrintProducts]: {
      next: (state, action) => (action.payload.entities.lineItems)
    }
  },
  mixLineItemsToPrintInitialState
);

////////////////////////////// ORDERS REDUCER
// Stores the information order map
const ordersInitialState: Map<string, NormalizedOrder> = {};
const orders = handleActions(
  {
    [combineActions(
      orderActions.fetchOrderByNumber
    )]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.orders })
    }
  },
  ordersInitialState
);

const mixOrderInitialState = null;
const currentMixOrderNumber = handleActions(
  {
    [combineActions(orderActions.fetchOrderByNumber)]: {
      next: (state, action) => action.payload.result
    },
    [actions.fetchProductionMixes]: {
      next: (state, action) => mixOrderInitialState
    },
  },
  mixOrderInitialState
);

////////////////////////////// MAP OF PRE PREPARATION INDEX
const mixLineItemsInitialState = {};
const mixLineItems = handleActions(
  {
    [combineActions(
      actions.fetchProductionMixes,
      actions.fetchProductionMix,
      actions.putProductionMixPrePreparedQuantity,
      orderActions.fetchOrderByNumber,
      orderActions.editLineItem
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.lineItems,
      }),
    },
  },
  mixLineItemsInitialState
);

////////////////////////////// ISSUES REDUCER
// Possible issues for the products
const mixIssuesInitialState = {};
const mixIssues = handleActions(
  {
    [combineActions(
      orderActions.fetchOrderByNumber,
      orderActions.updateLineItem,
      actions.fetchProductionMixes,
      actions.fetchProductionMix,
      actions.postStartBlendPrePreparation,
      actions.putProductionMixPrePreparedQuantity
    )]: {
      next: (state, action) => ({ ...state, ...action.payload.entities.issues })
    }
  },
  mixIssuesInitialState
);

////////////////////////////// LOADING REDUCER
type Loading = {
  gettingProductionMixes: boolean,
};

const loadingInitialState = {
  gettingProductionMixes: false,
};

const loading = handleActions(
  {
    [combineActions(actions.fetchProductionMixesRequest)]: (
      state,
      action
    ) => ({
      ...state,
      gettingProductionMixes: true,
    }),
    [combineActions(actions.fetchProductionMixes)]: (
      state,
      action
    ) => ({
      ...state,
      gettingProductionMixes: false,
    }),
  },
  loadingInitialState
);

const blendPrePreparations: State = combineReducers({
  currentProductionMixesList,
  productionMixes,
  currentMix,
  orders,
  currentMixOrderNumber,
  mixLineItems,
  mixLineItemsToPrint,
  mixIssues,

  loading,
});

export default blendPrePreparations;
