// @flow
import PreparationPage from "./pages/PreparationPage";
import PackagingPage from "./pages/PackagingPage";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import { PrePreparationIndexPage, PrePreparationProductPage } from "./pre-preparation";
import { BlendPrePreparationIndexPage, BlendPrePreparationMixPage } from "./blend-pre-preparation"
import { ConfigurationPage } from "./configurationModule";
import { LoginPage, LogoutPage } from "./employee";

const routes = [
  {
    path: "/blend-pre-preparation",
    component: BlendPrePreparationIndexPage,
    exact: true,
  },
  {
    path: "/blend-pre-preparation/:mixId",
    component: BlendPrePreparationMixPage,
    exact: true,
  },
  {
    path: "/pre-preparation/:productSlug",
    component: PrePreparationProductPage,
    exact: true,
  },
  {
    path: "/pre-preparation",
    component: PrePreparationIndexPage,
    exact: true,
  },
  {
    path: "/preparation",
    component: PreparationPage,
    exact: true
  },
  {
    path: "/packaging",
    component: PackagingPage,
    exact: true
  },
  {
    path: "/erro",
    component: ErrorPage,
    exact: true
  },
  {
    path: "/login",
    component: LoginPage,
    exact: true
  },
  {
    path: "/logout",
    component: LogoutPage,
    exact: true
  },
  {
    path: "/",
    component: HomePage,
    exact: true
  },
  {
    path: "/configuration",
    component: ConfigurationPage,
    exact: true
  }
];

export default routes;
