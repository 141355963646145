// @flow
import React from "react";
import qs from "qs";
// Components
import { Redirect } from "react-router-dom";
import ConfigurationBox from "../components/ConfigurationBox";
// Composers
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { employeeConnector } from "../../employee";
// Import Actions
import * as Actions from "../actions";
// Selectors
import * as Selectors from "../selectors";
// Types
import type { ContextRouter } from "react-router-dom";
import type { Dispatch } from "../../common";

type RouterProps = ContextRouter;
type Props = {
  distributionCenter: string,
  gettingConfigurationLoader: boolean,
  settingConfigurationLoader: boolean,
  handleSetDistributionCenter: (distributionCenter: string) => void
} & RouterProps;

class ConfigurationPage extends React.Component {
  props: Props;

  componentDidUpdate(prevProps) {
    // If configuration has just been set
    if (
      prevProps.settingConfigurationLoader &&
      !this.props.settingConfigurationLoader
    ) {
      // If there is a saved configuration
      if (this.props.distributionCenter) {
        const params = qs.parse(
          this.props.location.search ? this.props.location.search.substr(1) : ""
        );

        this.props.history.push(params.to ? `/${params.to}` : "/");
      }
    }
  }

  render() {
    const params = qs.parse(
      this.props.location.search ? this.props.location.search.substr(1) : ""
    );

    // If we are not logged in we should redirect to the loginPage
    if (!this.props.employeeLoggedIn) {
      return <Redirect to={params.to ? `/login?to=${params.to}` : "/login"} />;
    }

    return (
      <ConfigurationBox
        isLoading={
          this.props.gettingConfigurationLoader ||
          this.props.settingConfigurationLoader
        }
        onSubmit={this.props.handleSetDistributionCenter}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    distributionCenter: Selectors.distributionCenterSelector(state),
    gettingConfigurationLoader: Selectors.gettingLoadingSelector(state),
    settingConfigurationLoader: Selectors.settingLoadingSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    handleSetDistributionCenter: (distributionCenter: string) => {
      dispatch(Actions.saveDistributionCenter(distributionCenter));
    }
  };
};

export default withRouter(
  employeeConnector(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ConfigurationPage)
  )
);
