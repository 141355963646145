// @flow
import React from 'react';

// Components
import Modal from 'react-modal';

// styles
import './CustomModal.css';

const CustomModal = ({ title, content, isOpened, closeModal }) => {
  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={isOpened}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error Selection Modal"
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        className="modal-total-price-limit-container"
        tabIndex="1"
        onKeyDown={() => {
          closeModal();
        }}
      >
        <h1>
          {title}{' '}
        </h1>
        <span>
          {content}
        </span>
        <input type={'submit'} autoFocus value={'OK'} />
      </div>
    </Modal>
  );
};

export default CustomModal;
