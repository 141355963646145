const colors = [
  "#1e2c84",
  "#be3ab0",
  "#973920",
  "#b999dd",
  "#6413a4",
  "#86205a",
  "#335f6a",
  "#48f2e1",
  "#620303",
  "#af9d23"
];

const blendColorsHelper = {
  colors
};

export default blendColorsHelper;
