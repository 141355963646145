import { createAction } from 'redux-actions';
import md5 from 'md5';
import api from '../api/lineItem';
import { cameraApi } from '../camera';
import { Buffer } from 'buffer';
// Types
import type { Dispatch } from '../common';
// Log Helper
import { logException } from '../logHelper';

// Post images
export const postLineItemImageRequest = createAction(
  'POST_LINE_ITEM_IMAGE_REQUEST',
);
export const postLineItemImage = createAction('POST_LINE_ITEM_IMAGE');

export const uploadProductImage = (activeLineItemId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(postLineItemImageRequest());
      // Uploading an image is a four step process:

      // 1. Get image from camera_service
      const { data } = await cameraApi.getImage();

      // We expect data.image to return a base64 encoded image
      const image = data.image;

      // 2. Request a signed URL from the server
      const metadataResponse = await api.postImageMetadata(image, md5(image));

      // 3. Upload the image directly to cloud storage
      const photoUploadResponse = await api.postImage(
        metadataResponse.signed_url,
        image,
      );

      // 4. Inform the server of the uploaded image and associate it to the line item.
      if (photoUploadResponse.status === 'error') {
        const uploadError = new Error('Upload Error');
        logException(uploadError, photoUploadResponse.info, 'error');
        throw uploadError;
      }
      const photoAttachment = await api.postImageAttachment(
        metadataResponse.signed_id,
        activeLineItemId,
        'Spree::LineItem',
        'image',
      );
      const response = { ...photoAttachment };
      dispatch(postLineItemImage(response));
    } catch (err) {
      dispatch(postLineItemImage(err));
    }
  };
};
